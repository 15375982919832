import React, { useState, useEffect } from "react";

import { CountdownCircleTimer } from "react-countdown-circle-timer";

import { Box, Flex } from "@chakra-ui/react";
import Pmd from "../../Components/Typography/Text/Pmd";

import { AiOutlinePauseCircle, AiOutlinePlayCircle } from "react-icons/ai";
import { PiArrowCounterClockwiseLight, PiPlayPauseLight } from "react-icons/pi";

import { zeroPad } from "../../utils/stringManipulation";

const Timer = () => {
  const times = [20, 50, 90];

  const [isPlaying, setIsPlaying] = useState(false);
  const [selectedMinutes, setSelectedMinutes] = useState(60);
  const [key, setKey] = useState(0);

  useEffect(() => {
    handleSelectedTime(times[0]);
  }, []);

  const handleSelectedTime = (val) => {
    setSelectedMinutes(val);
    setKey(prev => prev === 0 ? 1 : 0);
  };

  const styles = {
    timeLeft: {
      color: "#000",
      fontSize: "36px",
      fontWeight: 500,
      lineHeight: "120%",
      letterSpacing: "0.2px",
    },
    timeSubHeading: {
      color: "#828282",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "120%",
    },
    btn: {
      height: "24px",
      width: "24px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      border: "1px solid #828282",
      borderRadius: "15px",
      // padding: "4px",
    },
    selectTimeBtn: {
      width: "60px",
      height: "32px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderColor: "#cbcacd",
    },
    selectTimeText: {
      fontSize: "12px",
      fontWeight: 500,
    },
  };

  const renderTime = (elapsedTime) => {
    const minute = (selectedMinutes - elapsedTime / 60) | 0;
    const second =
      elapsedTime !== 60 && elapsedTime !== 0
        ? (60 - (elapsedTime % 60)) | 0
        : 0;
    return (
      <Box>
        <Pmd sx={styles.timeLeft}>
          {zeroPad(minute,2)}:
          {zeroPad(second,2)}
        </Pmd>
        <Pmd sx={styles.timeSubHeading}>FOCUS!</Pmd>
      </Box>
    );
  };

  return (
    <Box
      textAlign={"center"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      paddingY={"32px"}
    >
      <CountdownCircleTimer
        key={key}
        isPlaying={isPlaying}
        initialRemainingTime={selectedMinutes * 60}
        duration={selectedMinutes * 60}
        strokeWidth={7}
        size={"180"}
        colors={[["#6750A4"]]}
        onComplete={() => console.log("times up")}
      >
        {({ elapsedTime }) => {
          // console.log(selectedMinutes - elapsedTime / 1000);
          return renderTime(elapsedTime);
        }}
      </CountdownCircleTimer>
      <Flex
        gap={"28px"}
        justifyContent={"center"}
        alignItems={"center"}
        paddingY={"5px"}
        marginTop={"12px"}
        marginBottom={"16px"}
      >
        <Box
          as="button"
          sx={styles.btn}
          onClick={() => {
            setIsPlaying(false);
            handleSelectedTime(selectedMinutes);
          }}
        >
          <PiArrowCounterClockwiseLight size={"16px"} color={"#828282"} />
        </Box>
        <Box as="button" onClick={() => setIsPlaying((prev) => !prev)}>
          {isPlaying ? (
            <AiOutlinePauseCircle size={"36px"} color={"#21005D"} />
          ) : (
            <AiOutlinePlayCircle size={"36px"} color={"#21005D"} />
          )}
        </Box>
        {/* <Box as="button" sx={styles.btn}>
          <PiPlayPauseLight size={"16px"} color={"#828282"} />
        </Box> */}
      </Flex>
      <Flex border={"1px solid #cbcacd"} borderRadius={"18px"}>
        {times.map((item, index) => {
          return (
            <Box
              key={index}
              as="button"
              onClick={() => handleSelectedTime(item)}
              sx={styles.selectTimeBtn}
              borderLeftRadius={index === 0 ? "18px" : "0px"}
              borderRightRadius={index === 2 ? "18px" : "0px"}
              borderWidth={`0px ${index === 1 ? "1px" : "0px"} 0px ${
                index === 1 ? "1px" : "0px"
              }`}
              bgColor={item === selectedMinutes ? "#e8def8" : "#fff"}
            >
              <Pmd
                style={styles.selectTimeText}
                color={
                  item === selectedMinutes ? "claydeck.brand.secondary" : "#000"
                }
              >{`${item} min`}</Pmd>
            </Box>
          );
        })}
      </Flex>
    </Box>
  );
};

export default Timer;