import { Box } from "@chakra-ui/react";

import TaskList from "./TaskList";
import Timer from "./Timer";
import TaskCalendar from "./TaskCalendar";


const Sidebar = (props) => {
  return (
    <Box position={"relative"}>
      <Box
        bgColor={"claydeck.white.primary"}
        boxShadow={"xl"}
        paddingBottom={"24px"}
        position={{ base: "absolute", lg: "sticky" }}
        top={0}
        left={{base: props.sidebarVisible ? "0px" : "-500px", lg: '0px'}}
        height={{base: 'min-content', lg: "100vh"}}
        overflowY={"scroll"}
        zIndex={1}
      >
        <TaskList />
        <Timer />
        <TaskCalendar />
      </Box>
    </Box>
  );
};

export default Sidebar;