import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Text,
    VStack,
    HStack,
    Scrollbar,
} from "@chakra-ui/react";
import {useEffect, useState} from "react";
import {NEW_API_URL} from "./redux/types";
import axios from "axios";
import {useSelector} from "react-redux";

const ParticipantListModal = (props) => {
    const { isOpen, onClose,username,openRoom } = props

    const [participantsData,setparticipantsData] = useState(props.participantsData)

    useEffect(()=>{
        setparticipantsData(props.participantsData)
    },[props.participantsData])
    const currentWorkspaceID = useSelector(
        (state) => state.user.selectedWorkspace.workspace_id
    );

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Participant List</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Accordion allowMultiple>
                        {participantsData.map((category, index) => (
                            <AccordionItem key={index}>
                                <AccordionButton>
                                    <Box flex="1" textAlign="left">
                                        {category.title}
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel pb={4}>
                                    <VStack spacing={4}>
                                        {category.entries.map((entry, entryIndex) => (
                                            <HStack key={entryIndex} spacing={4}>
                                                <Text>{entry}</Text>
                                                {category.title === "Participants" && <>
                                                    <Button onClick={()=>{
                                                        axios
                                                            .post(
                                                                NEW_API_URL +
                                                                "community/add_to_blocklist/" +
                                                                currentWorkspaceID +
                                                                "/" +
                                                                openRoom +
                                                                "/" +
                                                                username +
                                                                "/",
                                                                {
                                                                    user_to_add: entry,
                                                                }
                                                            )
                                                            .then((r) => {
                                                                console.log("added!");
                                                                // setForceRefetch(forceRefetch + 1);
                                                            })
                                                            .catch((err) => {
                                                                console.log(err);
                                                            });
                                                    }} colorScheme="red">Block</Button>
                                                </>}
                                                {category.title === "Blocked" && <>
                                                    <Button onClick={()=>{
                                                        axios
                                                            .post(
                                                                NEW_API_URL +
                                                                "community/remove_from_blocklist/" +
                                                                currentWorkspaceID +
                                                                "/" +
                                                                openRoom +
                                                                "/" +
                                                                username +
                                                                "/",
                                                                {
                                                                    user_to_remove: entry,
                                                                }
                                                            )
                                                            .then((r) => {
                                                                console.log("added!");
                                                                // setForceRefetch(forceRefetch + 1);
                                                            })
                                                            .catch((err) => {
                                                                console.log(err);
                                                            });
                                                    }} colorScheme="teal">Unblock</Button>
                                                </>}
                                                {category.title === "Reported" && <>
                                                    <Button onClick={()=>{
                                                        axios
                                                            .post(
                                                                NEW_API_URL +
                                                                "community/add_to_blocklist/" +
                                                                currentWorkspaceID +
                                                                "/" +
                                                                openRoom +
                                                                "/" +
                                                                username +
                                                                "/",
                                                                {
                                                                    user_to_add: entry,
                                                                }
                                                            )
                                                            .then((r) => {
                                                                console.log("added!");
                                                                // setForceRefetch(forceRefetch+1)
                                                                axios
                                                                    .post(
                                                                        NEW_API_URL +
                                                                        "community/remove_from_participants/" +
                                                                        currentWorkspaceID +
                                                                        "/" +
                                                                        openRoom +
                                                                        "/" +
                                                                        username +
                                                                        "/",
                                                                        {
                                                                            user_to_remove: entry,
                                                                        }
                                                                    )
                                                                    .then((r) => {
                                                                        console.log("added!");
                                                                        axios
                                                                            .post(
                                                                                NEW_API_URL +
                                                                                "community/remove_from_reports/" +
                                                                                currentWorkspaceID +
                                                                                "/" +
                                                                                openRoom +
                                                                                "/" +
                                                                                username +
                                                                                "/",
                                                                                {
                                                                                    user_to_remove: entry,
                                                                                }
                                                                            )
                                                                            .then((r) => {
                                                                                console.log("added!");
                                                                                // setForceRefetch(forceRefetch + 1);
                                                                            })
                                                                            .catch((err) => {
                                                                                console.log(err);
                                                                            });
                                                                    })
                                                                    .catch((err) => {
                                                                        console.log(err);
                                                                    });
                                                            })
                                                            .catch((err) => {
                                                                console.log(err);
                                                            });
                                                    }} colorScheme="red">Block</Button>
                                                    <Button onClick={()=>{
                                                        axios
                                                            .post(
                                                                NEW_API_URL +
                                                                "community/remove_from_reports/" +
                                                                currentWorkspaceID +
                                                                "/" +
                                                                openRoom +
                                                                "/" +
                                                                username +
                                                                "/",
                                                                {
                                                                    user_to_remove: entry,
                                                                }
                                                            )
                                                            .then((r) => {
                                                                console.log("added!");
                                                                // setForceRefetch(forceRefetch + 1);
                                                            })
                                                            .catch((err) => {
                                                                console.log(err);
                                                            });

                                                    }} colorScheme="teal">Ignore</Button>
                                                </>}
                                                {category.title === "Pending" && <>
                                                    <Button onClick={()=>{
                                                        axios
                                                            .post(
                                                                NEW_API_URL +
                                                                "community/remove_from_pending/" +
                                                                currentWorkspaceID +
                                                                "/" +
                                                                openRoom +
                                                                "/" +
                                                                username +
                                                                "/",
                                                                {
                                                                    user_to_remove: entry,
                                                                }
                                                            )
                                                            .then((r) => {
                                                                console.log("removed!");
                                                            })
                                                            .catch((err) => {
                                                                console.log(err);
                                                            });
                                                    }} colorScheme="red">Ignore</Button>
                                                    <Button onClick={()=>{

                                                        axios
                                                            .post(
                                                                NEW_API_URL +
                                                                "community/add_to_participants/" +
                                                                currentWorkspaceID +
                                                                "/" +
                                                                openRoom +
                                                                "/" +
                                                                username +
                                                                "/",
                                                                {
                                                                    user_to_add: entry,
                                                                }
                                                            )
                                                            .then((r) => {
                                                                console.log("added!");
                                                                // setForceRefetch(forceRefetch+1)
                                                                axios
                                                                    .post(
                                                                        NEW_API_URL +
                                                                        "community/remove_from_pending/" +
                                                                        currentWorkspaceID +
                                                                        "/" +
                                                                        openRoom +
                                                                        "/" +
                                                                        username +
                                                                        "/",
                                                                        {
                                                                            user_to_remove: entry,
                                                                        }
                                                                    )
                                                                    .then((r) => {
                                                                        console.log("added!");
                                                                    })
                                                                    .catch((err) => {
                                                                        console.log(err);
                                                                    });
                                                            })
                                                            .catch((err) => {
                                                                console.log(err);
                                                            });


                                                    }} colorScheme="teal">Allow</Button>
                                                </>}
                                            </HStack>
                                        ))}
                                    </VStack>
                                </AccordionPanel>
                            </AccordionItem>
                        ))}
                    </Accordion>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={onClose}>Close</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default ParticipantListModal;
