import React from "react";
import { Box, Flex, Image, Text, Grid } from "@chakra-ui/react";
import COLORS from "../../Components/colors";
import book_logo from "../../assets/book_logo.png";
import notes2_blurred from "../../assets/notes2_blurred.png";
import recommended from "../../assets/recommended.png";
import SavedFeedArticles from "../../assets/SavedFeedArticles.png";
import Psm from "../../Components/Typography/Text/Psm";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function StudyResources() {
  const navigate = useNavigate();
  const username = useSelector((state) => state.user.username);
  const workspaceId = useSelector(
    (state) => state.user.selectedWorkspace.workspace_id
  );

  let data = [
    {
      id: 1,
      image: book_logo,
      nav_path: "./books",
      api_path: `library/get_all_books/${workspaceId}/${username}/`,
      heading: "Books",
    },
    {
      id: 2,
      image: notes2_blurred,
      nav_path: "./notes",
      api_path: null,
      heading: "Notes",
    },
    {
      id: 3,
      image: recommended,
      nav_path: "./must-reads",
      api_path: `library/get_must_reads/${workspaceId}/${username}/`,
      heading: "Claydeck Must Reads",
    },
    {
      id: 4,
      image: SavedFeedArticles,
      nav_path: "./saved-feeds",
      api_path: `library/get_must_reads/${workspaceId}/${username}/`,
      heading: "Saved Feed Articles",
    },
  ];

  return (
    <Flex gap={"4px"} flexWrap={"wrap"}>
      {data.map((item, index) => {
        return (
          <Flex
            key={index}
            onClick={() => {
              navigate(item.nav_path, { state: { item: item } });
            }}
            cursor="pointer"
            height={"210px"}
            maxW="180px"
            backgroundColor={COLORS.white}
            justifyContent={"center"}
            direction={"column"}
            alignItems={"center"}
            borderRadius={"6px"}
            padding={2}
            margin={6}
          >
            <Image
              src={item.image}
              alt={item.heading}
              height="150px"
              objectFit="contain"
            />
            <Psm
              className="my-2 text-align-center"
              color={COLORS.darkPurple}
              textAlign="center"
            >
              {item.heading}
            </Psm>
          </Flex>
        );
      })}
    </Flex>
  );
}
