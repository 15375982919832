import { useState, useEffect } from "react";

import {
  Container,
  Box,
  Flex,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
} from "@chakra-ui/react";
import H2 from "../../Components/Typography/Heading/H2";
import Psm from "../../Components/Typography/Text/Psm";
import Pmd from "../../Components/Typography/Text/Pmd";
import InputOutline from "../../Components/Input/InputOutline";
import InputDate from "../../Components/Input/InputDate";
import ButtonPrimary from "../../Components/Button/ButtonPrimary";

import {AiOutlineClockCircle} from "react-icons/ai";
import { BiCalendarAlt } from "react-icons/bi";
import {HiPlus} from "react-icons/hi";
import { MdEdit, MdDelete } from "react-icons/md";

import axios from "axios";
import {NEW_API_URL} from "../../redux/types";
import { useSelector } from "react-redux";

const colors = ["#B79BFF", "#A6F7E2", "#FFE5A5", "#C7FFA5"];
const initialTask = {
  name: "",
  time_estimate: "",
  pending_date: "",
};

const Tasks = () => {

  const {isOpen, onOpen, onClose} = useDisclosure();
  const username = useSelector(state => state.user.username);
  const workspaceId = useSelector(state => state.user.selectedWorkspace.workspace_id);

  const [fetchLoading, setFetchLoading] = useState(false);
  const [taskList, setTaskList] = useState([]);
  const [task, setTask] = useState(initialTask);
  const [selectedTask, setSelectedTask] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (username !== "" && workspaceId !== "") {fetchTasks();}
  }, [username, workspaceId]);

  const fetchTasks = async () => {
    setFetchLoading(true);
    try {
      await axios
        .get(
          `${NEW_API_URL}gamification/get_metrics/${username}/${workspaceId}`
        )
        .then((response) => {
          // console.log("laskdfj", response.data.metrics.todays_tasks.tasks);
          setTaskList(response?.data?.metrics?.todays_tasks?.tasks);
        })
        .catch((error) => {
          console.error(error);
          // Toast.show({
          //   type: "error",
          //   text1: "Error",
          //   text2: "Couldn't load data",
          // });
        });
    } catch(e) {
      console.error(e);
      // Toast.show({
      //   type: "error",
      //   text1: "Error",
      //   text2: "Something unexpected happened",
      // });
    } finally {
      setFetchLoading(false);
    }
  };

  const handleTask = (e) => {
    setTask((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleModalClose = () => {
    onClose();
    setTask(initialTask);
    setSelectedTask(null);
    setError(false);
  }

  const editTask = (index) => {
    setSelectedTask(index);
    setTask(taskList[index]);
    onOpen();
  }

  const handleCreateTask = () => {

    for (const prop in task) {
      if (task[prop] === "") {
        setError(true);
        return;        
      }
    }

    const index = selectedTask === null ? taskList.length : selectedTask;
    
    const newTaskList = taskList;
    newTaskList[index] = task;
    setTaskList(newTaskList);
    handleAddTaskButton();
  }

  const handleAddTaskButton = async () => {
    setLoading(true);

    const requestBody = {
      success: true,
      metrics: {
        todays_tasks: {
          tasks: taskList,
        },
      },
    };

    await axios
      .post(
        `${NEW_API_URL}/gamification/update_metrics/${username}/${workspaceId}/`,
        requestBody
      )
      .then((response) => {
        console.log("datea", response.data);
        handleModalClose();
        fetchTasks();
        setLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
        handleModalClose();
      });
    setLoading(false);
    handleModalClose();
  };

  return (
    <>
      <Container
        minHeight={"100vh"}
        minWidth={{
          base: "max-content",
          md: "350px",
          lg: "500px",
          xl: "650px",
        }}
      >
        <Flex
          width={"100%"}
          gap={{ base: "16px", lg: "24px" }}
          direction={"column"}
          margin={{ base: "36px 0px", lg: "72px 0px" }}
        >
          <Flex justifyContent={"space-between"} alignItems={"center"}>
            <H2 fontSize="18px">Edit Lesson Plan</H2>
            <Flex gap={{ base: "6px", lg: "10px" }}>
              <Flex
                as={"button"}
                onClick={onOpen}
                bgColor={"claydeck.brand.secondary"}
                alignItems={"center"}
                borderRadius={"15px"}
                padding="4px"
              >
                <Icon
                  as={HiPlus}
                  boxSize={{ base: "16px", lg: "20px" }}
                  color={"claydeck.white.primary"}
                />
              </Flex>
              <Flex
                as={"button"}
                bgColor={"claydeck.brand.secondary"}
                alignItems={"center"}
                borderRadius={"15px"}
                padding="4px"
              >
                <Icon
                  as={MdDelete}
                  boxSize={{ base: "16px", lg: "20px" }}
                  color={"claydeck.white.primary"}
                />
              </Flex>
            </Flex>
          </Flex>
          {taskList.map((task, index) => {
            return (
              <Box
                key={index}
                sx={styles.task}
                borderLeft={`9px solid ${colors[index % 4]} !important`}
              >
                <Flex justifyContent={"space-between"} alignItems={"center"}>
                  <Box>
                    <Pmd sx={styles.taskTitle}>{task.name}</Pmd>
                    <Flex gap="10px">
                      <Flex width="80px">
                        <Icon
                          as={BiCalendarAlt}
                          color={"claydeck.text.tertiary"}
                        />
                        <Psm sx={styles.taskDetail}>{task.pending_date}</Psm>
                      </Flex>
                      <Flex width="80px">
                        <Icon
                          as={AiOutlineClockCircle}
                          color={"claydeck.text.tertiary"}
                        />
                        <Psm sx={styles.taskDetail}>
                          {task.time_estimate} minutes
                        </Psm>
                      </Flex>
                    </Flex>
                  </Box>
                  <Flex
                    as={"button"}
                    onClick={() => editTask(index)}
                    alignItems={"center"}
                    border="1px solid #828282"
                    borderRadius={"15px"}
                    padding="4px"
                  >
                    <Icon
                      as={MdEdit}
                      boxSize="16px"
                      color="claydeck.text.tertiary"
                    />
                  </Flex>
                </Flex>
              </Box>
            );
          })}
        </Flex>
      </Container>
      {/* add/edit task modal */}
      <Modal isOpen={isOpen} onClose={handleModalClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign={"center"}>Add Lesson Plan</ModalHeader>
          <ModalBody>
            {error ? (
              <Pmd color={"red"} margin={"6px 0px"}>
                Please fill all the fields
              </Pmd>
            ) : (
              <></>
            )}
            <Flex direction={"column"} gap={"16px"}>
              <InputOutline
                name="name"
                placeholder="Enter plan name"
                onChange={(e) => handleTask(e)}
                value={task.name}
              />
              <InputOutline
                type="number"
                name="time_estimate"
                placeholder="Enter estimated time to finish"
                value={task.time_estimate}
                onChange={(e) => handleTask(e)}
              />
              <InputDate
                name="pending_date"
                placeholder="Select date of conclusion"
                onChange={(e) => handleTask(e)}
                value={task.pending_date}
              />
            </Flex>
          </ModalBody>
          <ModalFooter>
            <ButtonPrimary
              color="claydeck.text.primary"
              mr={3}
              onClick={handleModalClose}
            >
              Back
            </ButtonPrimary>
            <ButtonPrimary
              isLoading={loading}
              onClick={handleCreateTask}
              bgColor={"claydeck.brand.primary"}
            >
              <Pmd color={"claydeck.white.primary"}>Create Plan</Pmd>
            </ButtonPrimary>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Tasks;

const styles = {
  task: {
    width: "100%",
    bgColor: "claydeck.white.primary",
    borderRadius: "5px",
    padding: { base: "12px 8px", md: "14px 10px", xl: "16px 12px" },
  },
  taskTitle: {
    color: "#1C1B1F",
    fontSize: { base: "14px", lg: "16px" },
    fontWeight: 500,
    lineHeight: "120%",
    letterSpacing: "0.15px",
    marginBottom: { base: "3px", lg: "4px" },
  },
  taskDetail: {
    color: "claydeck.text.tertiary",
    fontSize: { base: "10px", lg: "12px" },
    marginLeft: "3px",
  },
};