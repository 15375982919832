import moment from "moment";

import {
  SET_NEW_USER,
  SET_REMEMBER_ME,
  API_URL,
  NEW_API_URL,
  CLEAR_ERRORS,
  LOADING_UI,
  LOADING_USER,
  MARK_NOTIFICATIONS_READ,
  SET_ERRORS,
  SET_GROUPS,
  SET_UNAUTHENTICATED,
  SET_USER,
  SET_KEY,
  SET_ACCESS_TOKEN,
  ADD_WORKSPACES,
  SET_WORKSPACE,
  LEAVE_WORKSPACE,
  SET_USERNAME,
  ADD_FEED,
  SET_SUBSCRIPTION,
  SET_PROFILE,
  STOP_LOADING_UI,
  LOGIN_LOADING_UI,
  STOP_LOGIN_LOADING_UI
} from "../types";
import { capitalizeFirstLetter } from "../../utils/stringManipulation";

import axios from "axios";
import { sortProfileData } from "../../utils/sortData";

let key;

// getAsyncStorageItem("KEY").then(val => key = val);

if (key) {
  // console.log('FOUND KEY', JSON.parse(localStorage.getItem('KEY')).key);
  axios.defaults.headers.common["Authorization"] = `Token ${
    JSON.parse(key).key
  }`;
  // setAuthorizationHeader(JSON.parse(localStorage.getItem("KEY")).key)
}

// authentication

const setAuthorizationHeader = (access, refresh) => (dispatch) => {
  const FBIdToken = `Token ${access}`;
  // console.log(FBIdToken);
  //localStorage.setItem('FBIdToken', FBIdToken);
  axios.defaults.headers.common["Authorization"] = FBIdToken;
  // console.log(access, refresh)
  dispatch({
    type: SET_KEY,
    payload: {
      access,
      refresh,
    },
  });
};

export const refreshToken = (token) => (dispatch) => {
  // dispatch({ type: LOADING_UI });

  axios
    .post(`${NEW_API_URL}user/refreshtoken/`, {
      refresh: token,
    })
    .then((res) => {
      // console.log("refresh res", JSON.stringify(res));
      // remove the below line and use setAuthorizationHeader function
      // when refresh token and access token both are recieved from api call
      dispatch({
        type: SET_ACCESS_TOKEN,
        payload: res.data.access,
      });
      // dispatch({ type: STOP_LOADING_UI });
    })
    .catch((error) => {
      console.log("refresh error", JSON.stringify(error));
      // dispatch({ type: STOP_LOADING_UI });

      dispatch(logoutUser());
    });
};

export const setNewUser = (state) => {
  return {
    type: SET_NEW_USER,
    payload: state
  }
}

export const signupUser =
  (newUserData, remember, navigate, securityQuestion) => (dispatch) => {
    console.log("new", newUserData);

    axios
      .post(NEW_API_URL + "user/register/", newUserData)
      .then(async (res) => {
        console.log("res", JSON.stringify(res));

        if (res.status === 201) {
          navigate("/plans", {
            state: {
              username: newUserData.username,
              password: newUserData.password,
              first_name: newUserData.first_name,
              last_name: newUserData.last_name
            },
          });
          dispatch({
            type: SET_REMEMBER_ME,
            payload: remember
          });
          dispatch(setNewUser(true));
        }
      })
      .catch((err) => {
        console.log("err sign up", JSON.stringify(err));
        if (err.response?.data) {
          console.log(err.response.data);
          const errors = err.response.data.errors;
          if (errors.hasOwnProperty("username")) {
            alert(capitalizeFirstLetter(errors.username[0]));
          }
          if (errors.hasOwnProperty("email")) {
            alert(capitalizeFirstLetter(errors.email[0]));
          } else alert("Unable to signup with the given info");
        } else alert("Unable to signup with the given info");
      });
  };

export const loginUser = (userData, remember) => async (dispatch) => {
  console.log(userData);
  dispatch({
    type: LOGIN_LOADING_UI
  })
  await axios
    .post(NEW_API_URL + "user/gettoken/", userData)
    .then(async (res) => {
      console.log("Logging INNNN");
      // console.log(JSON.stringify(res));

      if (res.status === 200) {
        // dispatch({
        //   type: SET_REMEMBER_ME,
        //   payload: remember
        // })
        dispatch({
          type: SET_USERNAME,
          payload: userData.username,
        });
        // calling get profile data api so that the user's details get saved in redux
        dispatch(getProfileData(res.data.access));
        dispatch(getUserWorkspaces(userData.username));
        dispatch(setAuthorizationHeader(res.data.access, res.data.refresh));
      }
      dispatch({type: STOP_LOGIN_LOADING_UI});
    })
    .catch((err) => {
      alert("Unable to login with the provided email/password");
      console.log("\nlogin err", err);
      dispatch({ type: STOP_LOGIN_LOADING_UI });
    });
};

export const logoutUser = () => async (dispatch) => {
  // await AsyncStorage.clear();
  delete axios.defaults.headers.common["Authorization"];

  dispatch({ type: SET_UNAUTHENTICATED });
};

// workspace

export const joinWorkspace = (workspaceId, username, firstName, lastName) => (dispatch) => {
  axios
    .post(`${NEW_API_URL}community/join_workspace/${workspaceId}/${username}/${firstName}/${lastName}/`)
    .then((res) => {
      // console.log("join workspace res", JSON.stringify(res.data));
    })
    .catch((err) => console.log("join workspace err", JSON.stringify(err)));

  axios
    .get(
      `${NEW_API_URL}gamification/initialize_metrics/${username}/${workspaceId}/${firstName}/${lastName}/`
    )
    .then((res) => {
      // console.log("initialize_metrics", JSON.stringify(res.data));
    })
    .catch((err) => console.log("initialize_metrics err", JSON.stringify(err)));

  axios
    .get(
      `${NEW_API_URL}lessonplan/initialize_lessonplan/${username}/${workspaceId}/`
    )
    .then((res) => {
      // console.log("initialize_lessonplan", JSON.stringify(res.data));
    })
    .catch((err) =>
      console.log("initialize_lessonplan err", JSON.stringify(err))
    );
};

export const getUserWorkspaces = (username) => (dispatch) => {
  // dispatch({type: LOADING_UI})
  axios
    .get(`${NEW_API_URL}community/get_user_workspaces/${username}/`)
    .then((res) => {
      // console.log("get user workspace res", JSON.stringify(res.data.workspaces[0]));
      dispatch(addWorkspaces(res.data.workspaces));
      dispatch(setSelectedWorkspace(res.data.workspaces[0]));
      // dispatch({type: STOP_LOADING_UI})
    })
    .catch((err) => {
      console.log("get user workspace err", JSON.stringify(err))
      // dispatch({ type: STOP_LOADING_UI });
    });
};

export const addWorkspaces = (workspaces) => (dispatch) => {
  dispatch({
    type: ADD_WORKSPACES,
    payload: workspaces,
  });
};

export const setSelectedWorkspace = (workspace) => (dispatch) => {
  dispatch({
    type: SET_WORKSPACE,
    payload: workspace,
  });
};

export const leaveWorkspace = (workspaceId, username) => (dispatch) => {
  axios
    .post(`${NEW_API_URL}community/leave_workspace/${workspaceId}/${username}/`)
    .then((res) => {
      // console.log("leave workspace res", JSON.stringify(res.data));
      if (res.data.success) {
        dispatch({
          type: LEAVE_WORKSPACE,
          payload: workspaceId,
        });
      }
    })
    .catch((err) => console.log("leave workspace err", JSON.stringify(err)));
};

export const addFeed = (feed) => (dispatch) => {
  dispatch({
    type: ADD_FEED,
    payload: feed,
  });
};

export const checkUserSubscription = (username) => (dispatch) => {
  axios
    .post(`${NEW_API_URL}community/check_subscription/${username}/`)
    .then((res) => {
      // console.log("check subscription res", JSON.stringify(res))
      if (res.data.message === "no subscription active") {
        dispatch({
          type: SET_SUBSCRIPTION,
          payload: false,
        });
      } else if (res.data.message === "subscription active") {
        dispatch({
          type: SET_SUBSCRIPTION,
          payload: true,
        });
      }
    })
    .catch((err) => console.log("check subscription err", JSON.stringify(err)));
};

// profile
export const setProfile = (profileData) => {
  return {
    type: SET_PROFILE,
    payload: profileData,
  };
};

export const getProfileData = (token) => (dispatch) => {
  const url = `${NEW_API_URL}user/profile/`;
  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  axios
    .get(url, axiosConfig)
    .then((res) => {
      // console.log("\nprofile res", JSON.stringify(res.data));
      const data = res?.data;

      const profileData = sortProfileData(data);
      dispatch(setProfile(profileData));
    })
    .catch((error) =>{
      console.log("\nprofile error", JSON.stringify(error));
    });
};

export const updateProfile = (token, username, data) => (dispatch) => {
  // dispatch({ type: LOADING_UI });

  const url = `${NEW_API_URL}user/profile/`;
  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const body = {
    username,
    email: username,
    first_name: data.firstName,
    last_name: data.lastName,
    phone_number: data.phoneNumber,
    study_hours_week: data.studyHabits,
    exam_date: moment(data.examDate).format("YYYY-MM-DD"),
  };

  axios
    .post(url, body, axiosConfig)
    .then((res) => {
      // console.log("\nupdate profile res", JSON.stringify(profileData));
      const data = res?.data;

      const profileData = sortProfileData(data);

      dispatch(setProfile(profileData));
      alert("Saved Changes");
      // dispatch({ type: STOP_LOADING_UI });
    })
    .catch((error) => {
      console.log("\nupdate profile error", JSON.stringify(error));

      // dispatch({ type: STOP_LOADING_UI });
      alert("There as an error. Please try again later.");
    });
};
