import {useState, useEffect} from "react";
import Joyride, { STATUS } from "react-joyride";

import { Box, Flex, Icon, Image, Select } from "@chakra-ui/react";
import H2 from "../../Components/Typography/Heading/H2";
import H3 from "../../Components/Typography/Heading/H3";
import Psm from "../../Components/Typography/Text/Psm";
import Pmd from "../../Components/Typography/Text/Pmd";
import ButtonPrimary from "../../Components/Button/ButtonPrimary";

import {AiOutlineArrowRight} from "react-icons/ai";
import {PiFunnelLight} from "react-icons/pi";
import {FaCircle} from "react-icons/fa";

import axios from "axios";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { NEW_API_URL } from "../../redux/types";
import { useNavigate } from "react-router-dom";
import { PieChart } from "react-minimal-pie-chart";
import {setNewUser} from "../../redux/actions/userActions";

const Home = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const newUser = useSelector(state => state.user.newUser);
  const username = useSelector(state => state.user.username);
  const workspaceId = useSelector(state => state.user.selectedWorkspace.workspace_id);

  const [{ run, steps }, setState] = useState({
    run: newUser,
    steps: [
      {
        content: <h2>Lets begin our journey</h2>,
        locale: { skip: <strong>SKIP</strong> },
        placement: "center",
        target: "body",
      },
      {
        title: "Productivity",
        content: (
          <p>
            You can check your productivity here. Click on it to see your lesson
            plan.
          </p>
        ),
        target: "#productivity",
        placement: "bottom",
      },
      {
        title: "Feeds",
        content: <p>Check your news reading status</p>,
        target: "#feeds",
        placement: "bottom",
      },
      {
        title: "Results",
        content: <p>Check your average test score percentage</p>,
        target: "#test-score",
        placement: "bottom",
      },
    ],
  });

  const [loading, setLoading] = useState(false);
  const [bookRecommendations, setBookRecommendations] = useState([]);
  const [feeds, setFeeds] = useState([]);
  const [badges, setBadges] = useState({
    answersWritten: 0,
    pointsEarned: 0,
    testsAttended: 0,
  });

  useEffect(() => {
    if (username !== "" && workspaceId !== "") {
      fetchUserStats();
    }
  }, [username, workspaceId]);

  const fetchUserStats = async () => {
    // dispatch(checkUserSubscription(username));
    try {
      setLoading(true);
      await axios
        .get(
          `${NEW_API_URL}gamification/get_metrics/${username}/${workspaceId}`
        )
        .then((response) => {
          // console.log(JSON.stringify(response.data))
          if (response.status === 200) {
            // setNewsReadStatus(response.data.metrics.news_reading_status);
            // setAvgTestScore(response.data.metrics.average_test_scores);
            // setReadingHoursDone(response.data.metrics.hours_reading_done);
            // setReadingHoursLeft(response.data.metrics.hours_reading_left);
            // setTodaysTasks(response.data.metrics.todays_tasks.tasks);
            setBadges(response.data.metrics?.badges?.badges);
  
            setBookRecommendations(
              response.data.metrics?.book_recommendations?.tasks || []
            );
            setFeeds(response.data.metrics?.headlines || []);
            // setNotifications(response.data.metrics.notifications.notifications);
  
            // notificationScheduler(
            //   response.data.metrics.notifications.notifications
            // );
          }
        })
        .catch((error) => {
          console.error(error);
          alert("Couldn't load data");
        });
    } catch (e) {
      console.error(e);
      alert("Something unexpected happened");
    } finally {
      setLoading(false);
    }
  };

  const handleTourEnd = (state) => {
    const { action, index, status, type } = state;

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      dispatch(setNewUser(false));
    }
  };

  const styles = {
    heading: {
      color: "claydeck.text.primary",
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "120%",
      letterSpacing: "0.2px",
    },
    bookContainer: {
      height: "200px",
      width: "150px",
      minWidth: "150px",
      bgColor: "claydeck.white.primary",
      flexDirection: "column",
      alignItems: "center",
      borderRadius: "5px",
      padding: "9px 15px",
      cursor: "pointer",
    },
    feeds: {
      // maxWidth: "750px",
      overflow: "hidden",
      overflowX: "scroll",
      gap: "18px",
      flexDirection: { base: "row", md: "column" },
      paddingBottom: "12px",
      marginTop: "18px",
    },
    feedContainer: {
      height: { base: "225px", md: "auto" },
      width: { base: "150px", md: "initial" },
      minWidth: "150px",
      bgColor: "claydeck.white.primary",
      display: { base: "flex", md: "initial" },
      flexDirection: "column",
      justifyContent: "space-between",
      borderRadius: { base: "5px", md: "10px" },
      padding: { base: "12px", md: "16px", lg: "24px" },
      cursor: "pointer",
    },
    feedContent: {
      height: "80%",
      flexDirection: { base: "column", md: "row-reverse" },
      gap: "16px",
      justifyContent: { base: "space-around", md: "flex-end" },
      alignItems: "center",
      marginBottom: { base: "8px", md: "0px" },
    },
    feedHeading: {
      color: "claydeck.brand.secondary",
      fontSize: { base: "14px", md: "16px" },
      display: "block",
      textOverflow: "ellipsis",
      wordWrap: "break-word",
      overflow: "hidden",
      maxHeight: "4.2em",
      lineHeight: "1.4em",
    },
    userStats: {
      // height: "116px",
      minWidth: "357px",
      backgroundColor: 'claydeck.white.primary',
      justifyContent: "space-around",
      alignItems: "center",
      borderRadius: "5px",
      paddingTop: "8px",
      paddingBottom: "7px",
      margin: "22px",
      marginBottom: "100px",
    },
    userStat: {
      textAlign: 'center',
      flexDirection: 'column',
      alignItems: 'center'
    },
    userStatIcon: {
      height: "48px",
      width: '48px',
      bgColor: "claydeck.white.primary",
      objectFit: 'contain',
      borderRadius: "4px",
      boxShadow: "0px 4px 20px 5px rgba(0, 0, 0, 0.03)",
      padding: "8px"
    },
    userStatTitle: {
      textAlign: 'center',
      color: 'claydeck.text.tertiary',
      fontSize: '12px',
      fontWeight:'400',
      lineHeight: '120%',
      marginTop: '12px',
      marginBottom: '8px'
    },
    userStatValue: {
      fontSize: '30px',
      fontWeight: 400
    },
    option: {
      bgColor: '#fff',
      color: '#000'
    },
    labelInfo: {
      color: '#000',
      fontSize: '14px',
      marginLeft: '8px'
    }
  };

    return (
      <Flex
        width={"100%"}
        direction={{ base: "column", "2xl": "row" }}
        justify="space-between"
      >
        <Joyride
          continuous
          callback={(state) => handleTourEnd(state)}
          run={run}
          steps={steps}
          hideCloseButton
          scrollToFirstStep
          showSkipButton
          showProgress
        />
        {/* home page content */}
        <Box padding={"24px 32px"}>
          <Flex
            height={"min-content"}
            maxWidth={"750px"}
            bgColor={"claydeck.brand.primary"}
            alignItems={"center"}
            gap={"8px"}
            borderRadius={"10px"}
            padding={"23px 28px"}
            paddingLeft={"25px"}
          >
            <Box>
              <H3
                color={"claydeck.white.primary"}
                fontSize={"18px"}
                fontWeight={600}
                lineHeight={"140%"}
              >
                Welcome back, Merin!
              </H3>
              <Psm
                color={"claydeck.white.secondary"}
                fontSize={"12px"}
                lineHeight={"140%"}
                marginTop={"14px"}
                marginBottom={"32px"}
              >
                You have unlocked 500 credit points this month. Your study
                habits are on track and check out today’s events to improve your
                productivity rate. Good luck!
              </Psm>
              <Box
                as="button"
                height={"36px"}
                width={"190px"}
                bgColor={"claydeck.white.primary"}
                borderRadius={"5px"}
              >
                <H3
                  color={"claydeck.text.primary"}
                  fontSize={"14px"}
                  lineHeight={"140%"}
                  letterSpacing={"0.2px"}
                >
                  Checkout Events
                </H3>
              </Box>
            </Box>
            <Image src="./assets/illustrations/gift.svg" height={"167px"} />
          </Flex>
          {/* book recommendations */}
          <Box marginTop={"47.5px"}>
            <Flex maxWidth={"750px"} justify={"space-between"} align={"center"}>
              <H3 sx={styles.heading}>Book Recommendations</H3>
              <Flex
                height={"24px"}
                width={"24px"}
                bgColor={"#6750A4"}
                justify={"center"}
                align={"center"}
                borderRadius={"15px"}
              >
                <Icon as={PiFunnelLight} color={"#fff"} boxSize={"16px"} />
              </Flex>
            </Flex>
            <Flex
              className="book-recommendations"
              gap={"16px"}
              overflow={"hidden"}
              overflowX={"scroll"}
              paddingBottom={"12px"}
              marginTop={"18px"}
              marginBottom={"36px"}
            >
              {bookRecommendations && bookRecommendations.map((book, index) => {
                return (
                  <Flex
                    key={index}
                    sx={styles.bookContainer}
                    onClick={() => window.open(book.pdf_link)}
                  >
                    <Pmd color={"claydeck.brand.secondary"} textAlign="center">
                      {book.title}
                    </Pmd>
                    <Image
                      overflow={"hidden"}
                      objectFit={"contain"}
                      src={
                        book?.image ? book.image : "./assets/images/NCERT.png"
                      }
                      height={"135px"}
                      width={"103px"}
                      marginTop={"8px"}
                      marginBottom={"6px"}
                    />
                    <Pmd color={"claydeck.text.tertiary"} textAlign={"center"}>
                      {book.author}
                    </Pmd>
                  </Flex>
                );
              })}
            </Flex>
          </Box>
          {/* feeds */}
          <Box id={"feeds"}>
            <H3 sx={styles.heading}>Feeds</H3>
            <Flex className="feeds-container" sx={styles.feeds}>
              {feeds && feeds.map((feed, index) => {
                return (
                  <Box
                    key={index}
                    sx={styles.feedContainer}
                    onClick={() => window.open(feed.source)}
                  >
                    <Flex sx={styles.feedContent}>
                      <H3 sx={styles.feedHeading}>{feed.title}</H3>
                      <Image
                        src={
                          feed?.image
                            ? feed.image
                            : "./assets/images/HinduLogo.png"
                        }
                        height={"75px"}
                        width={"100px"}
                        objectFit={"contain"}
                        borderRadius={"4px"}
                      />
                    </Flex>
                    <Pmd
                      textAlign={"right"}
                      fontSize={{ base: "11px", md: "14px" }}
                    >
                      {moment(feed.pub_date).format("DD/MM/YYYY")}
                    </Pmd>
                  </Box>
                );
              })}
            </Flex>
          </Box>
        </Box>
        {/* user stats */}
        <Flex
          bgColor={"claydeck.white.primary"}
          direction={"column"}
          align={"center"}
        >
          <Flex
            direction={"row"}
            maxWidth={{ base: "100%", "2xl": "min-content" }}
            flexWrap={"wrap"}
            justify={"center"}
            paddingTop={{ base: "16px", md: "60px", "2xl": 0 }}
          >
            {/* user's test data */}
            <Flex
              sx={styles.userStats}
              className="shadow-primary"
              id="test-score"
            >
              <Flex sx={styles.userStat}>
                <Image
                  src={"./assets/icons/check-shield.svg"}
                  sx={styles.userStatIcon}
                />
                <Pmd sx={styles.userStatTitle}>QP Submission</Pmd>
                <H2 sx={styles.userStatValue} color={"#4702CB"}>
                  {badges?.answersWritten}
                </H2>
              </Flex>
              <Box height={"110px"} width={"0.5px"} bgColor={"#000"} />
              <Flex sx={styles.userStat}>
                <Image
                  src={"./assets/icons/star.png"}
                  sx={styles.userStatIcon}
                />
                <Pmd sx={styles.userStatTitle}>Expert Points</Pmd>
                <H2 sx={styles.userStatValue} color={"#0BC500"}>
                  {badges?.pointsEarned}
                </H2>
              </Flex>
              <Box height={"110px"} width={"0.5px"} bgColor={"#000"} />
              <Flex sx={styles.userStat}>
                <Image
                  src={"./assets/icons/presence.png"}
                  sx={styles.userStatIcon}
                />
                <Pmd sx={styles.userStatTitle}>Test Attendance</Pmd>
                <H2 sx={styles.userStatValue} color={"#C47501"}>
                  {badges?.testsAttended}
                </H2>
              </Flex>
            </Flex>
            <Flex justify={"center"}>
              <Flex
                width={"284px"}
                backgroundColor={"#e9ffdb"}
                textAlign={"center"}
                flexDirection={"column"}
                alignItems={"center"}
                borderRadius={"16px"}
                padding={"25px"}
                paddingTop={"71px"}
                margin={{ base: "0 16px", "2xl": "0px 56px" }}
                position={"relative"}
              >
                <Flex
                  height={"91px"}
                  width={"91px"}
                  backgroundColor={"#e9ffdb"}
                  justify={"center"}
                  align={"flex-end"}
                  borderRadius={"50px"}
                  position={"absolute"}
                  top={"-45px"}
                >
                  <Image src={"./assets/images/bulb.png"} height={"64px"} />
                </Flex>
                <H2
                  color={"claydeck.text.primary"}
                  fontSize={"20px"}
                  fontWeight={600}
                  lineHeight={"120%"}
                  letterSpacing={"0.2px"}
                  marginBottom={"10px"}
                >
                  Well Done!!
                </H2>
                <Pmd
                  color={"#2c2c2c"}
                  fontSize={"14px"}
                  fontWeight={400}
                  lineHeight={"140%"}
                  marginBottom={"20px"}
                >
                  You have completed almost 60 percent of tasks scheduled for
                  this week. Keep it up. Unlock new levels by taking a model
                  UPSC test here!
                </Pmd>
                <Box
                  as="button"
                  height={"36px"}
                  width={"207px"}
                  borderRadius={"5px"}
                  border={"1px solid #21005D"}
                >
                  <Pmd
                    color={"claydeck.brand.primary"}
                    fontSize={"14px"}
                    fontWeight={500}
                  >
                    Take Test
                  </Pmd>
                </Box>
              </Flex>
            </Flex>
          </Flex>
          {/* pie chart */}
          <Box id={"productivity"} margin={"65px 32px"}>
            <Flex justify={"space-between"} align={"center"}>
              <ButtonPrimary onClick={() => navigate('/lesson-plan')} display="flex" gap={"6px"} alignItems={"center"}>
                <H3>Overall Productivity</H3>
                <Icon as={AiOutlineArrowRight} />
              </ButtonPrimary>
              <Select
                variant="filled"
                iconColor="#fff"
                iconSize="18px"
                placeholder="Range"
                height={"36px"}
                width={"max-content"}
                bgColor={"claydeck.brand.primary"}
                color={"#fff"}
                fontSize={"14px"}
              >
                <option value={"daily"} style={styles.option}>
                  Daily
                </option>
                <option value={"weekly"} style={styles.option}>
                  Weekly
                </option>
                <option value={"monthly"} style={styles.option}>
                  Monthly
                </option>
              </Select>
            </Flex>
            <Flex
              gap={"15px"}
              justify={"space-between"}
              align={"center"}
              margin={"40px 0px"}
            >
              <PieChart
                style={{ height: "156px", width: "156px" }}
                data={[
                  { title: "One", value: 10, color: "#21005D" },
                  { title: "Two", value: 15, color: "#9C27B0" },
                  { title: "Three", value: 20, color: "#D81B60" },
                  { title: "One", value: 30, color: "#FF9800" },
                  { title: "Two", value: 5, color: "#C0CA33" },
                  { title: "Three", value: 25, color: "#D7D7D7" },
                ]}
                lineWidth={20}
                // label={({ dataEntry }) => dataEntry.value}
                labelStyle={{
                  fontSize: "28px",
                  fontFamily: "Roboto, sans-serif",
                  fill: "#000",
                }}
                labelPosition={0}
                paddingAngle={2}
              />
              <Flex direction={"column"} gap={"8px"}>
                <Flex align={"center"}>
                  <Icon as={FaCircle} boxize={"9px"} color={"#21005D"} />
                  <Pmd sx={styles.labelInfo}>Group assignments</Pmd>
                </Flex>
                <Flex align={"center"}>
                  <Icon as={FaCircle} boxize={"9px"} color={"#9C27B0"} />
                  <Pmd sx={styles.labelInfo}>News reading</Pmd>
                </Flex>
                <Flex align={"center"}>
                  <Icon as={FaCircle} boxize={"9px"} color={"#D81B60"} />
                  <Pmd sx={styles.labelInfo}>Research</Pmd>
                </Flex>
                <Flex align={"center"}>
                  <Icon as={FaCircle} boxize={"9px"} color={"#FF9800"} />
                  <Pmd sx={styles.labelInfo}>Room engagements</Pmd>
                </Flex>
                <Flex align={"center"}>
                  <Icon as={FaCircle} boxize={"9px"} color={"#C0CA33"} />
                  <Pmd sx={styles.labelInfo}>Answering question papers</Pmd>
                </Flex>
                <Flex align={"center"}>
                  <Icon as={FaCircle} boxize={"9px"} color={"#D7D7D7"} />
                  <Pmd sx={styles.labelInfo}>Other tasks</Pmd>
                </Flex>
              </Flex>
            </Flex>
          </Box>
          {/* </Box> */}
        </Flex>
      </Flex>
    );
}

export default Home;
