import {useState, useEffect} from "react";
import { useLocation, useParams } from "react-router-dom";

import { Container, Flex, Box, Icon } from "@chakra-ui/react";
import H2 from "../../Components/Typography/Heading/H2";
import Psm from "../../Components/Typography/Text/Psm";
import Pmd from "../../Components/Typography/Text/Pmd";

import {AiOutlineClockCircle} from "react-icons/ai";
import { HiDotsHorizontal } from "react-icons/hi";
import { MdDelete, MdEdit } from "react-icons/md";

const LessonPlanDetails = () =>  {

    const {id} = useParams();
    const location = useLocation();

   const [planDetails, setPlanDetails] = useState([]);

   useEffect(() => {
     setPlanDetails(location.state.topics);
   }, [location]);

   const styles = {
     task: {
       width: "100%",
       bgColor: "claydeck.white.primary",
       gap: "14px",
      //  alignItems: "center",
       border: "1.8px solid #a6a6a6",
       borderRadius: "5px",
     },
     taskTitle: {
       color: "#1C1B1F",
       fontSize: { base: "14px", lg: "16px" },
       fontWeight: 500,
       lineHeight: "120%",
       letterSpacing: "0.15px",
       marginBottom: { base: "3px", lg: "4px" },
     },
     taskDetail: {
       color: "claydeck.text.tertiary",
       fontSize: { base: "10px", lg: "12px" },
       fontWeight: 500,
     },
   };

   return (
     <Box width={"100%"} bgColor={"claydeck.white.primary"}>
       <Container
         minHeight={"100vh"}
         minWidth={{
           base: "max-content",
           md: "350px",
           lg: "500px",
           xl: "650px",
         }}
       >
         <Flex
           width={"100%"}
           gap={{ base: "16px", lg: "24px" }}
           direction={"column"}
           margin={{ base: "36px 0px", lg: "72px 0px" }}
         >
           <Flex justifyContent={"space-between"} alignItems={"center"}>
             <H2 fontSize="18px">{id}</H2>
             <Flex as={"button"}>
               <Icon
                 as={HiDotsHorizontal}
                 boxSize="24px"
                 color="claydeck.text.tertiary"
               />
             </Flex>
           </Flex>
           {planDetails.map((plan, index) => {
             return (
               <Flex key={index} sx={styles.task}>
                 <Flex
                   bgColor={"rgba(103, 80, 164, 0.12)"}
                   justifyContent={"center"}
                   align={"center"}
                 >
                   <H2
                     color={"claydeck.brand.secondary"}
                     fontSize="16px"
                     padding={"6px"}
                   >
                     {index + 1}
                   </H2>
                 </Flex>
                 <Flex
                   width="100%"
                   justifyContent={"space-between"}
                   alignItems={"center"}
                   padding={{
                     base: "12px 8px",
                     md: "14px 10px",
                     xl: "16px 12px",
                   }}
                 >
                   <Box>
                     <Pmd sx={styles.taskTitle}>{plan.name}</Pmd>
                     <Flex gap="6px" alignItems={"center"}>
                       <Icon
                         as={AiOutlineClockCircle}
                         color="claydeck.text.tertiary"
                       />
                       <Psm sx={styles.taskDetail}>{plan.time_estimate} Hours</Psm>
                     </Flex>
                   </Box>
                   <Flex gap="8px" align={"center"}>
                     <Flex
                       as={"button"}
                       alignItems={"center"}
                       border="1px solid #828282"
                       borderRadius={"15px"}
                       padding="3px"
                     >
                       <Icon
                         as={MdEdit}
                         boxSize="14px"
                         color="claydeck.text.tertiary"
                       />
                     </Flex>
                     <Flex
                       as={"button"}
                       height={"22px"}
                       width="22px"
                       justify={"center"}
                       align={"center"}
                       bgColor={"#ffe6e9"}
                       borderRadius={"15px"}
                     >
                       <Icon
                         as={MdDelete}
                         color={"#FF0E36"}
                         borderRadius="15px"
                       />
                     </Flex>
                   </Flex>
                 </Flex>
               </Flex>
             );
           })}
         </Flex>
       </Container>
     </Box>
   );
}

export default LessonPlanDetails;