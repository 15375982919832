import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Flex, Spinner } from "@chakra-ui/react";

import H1 from "../../Components/Typography/Heading/H1";
import Psm from "../../Components/Typography/Text/Psm";
import Pmd from "../../Components/Typography/Text/Pmd";

import { AddIcon, ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

import axios from "axios";
import { useSelector } from "react-redux";
import { NEW_API_URL } from "../../redux/types";

const TaskList = () => {
  const navigate = useNavigate();
  const username = useSelector((state) => state.user.username);
  const workspaceId = useSelector(
    (state) => state.user.selectedWorkspace.workspace_id
  );

  const [todaysTasks, setTodaysTasks] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (workspaceId !== "") {
      fetchUserStats();
    }
  }, [workspaceId]);

  const fetchUserStats = async () => {
    try {
      setIsLoading(true);
      await axios
        .get(
          `${NEW_API_URL}gamification/get_metrics/${username}/${workspaceId}`
        )
        .then((response) => {
          console.log("tasks response", response?.data?.metrics);
          if (response?.status === 200) {
            console.log("tasks set");
            setTodaysTasks(response.data.metrics?.todays_tasks?.tasks || []);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("todays task error", error);
          setIsLoading(false);
        });
    } catch (e) {
      console.error(e);
    }
  };

  const styles = {
    task: {
      color: "#1C1B1F",
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "120%",
      letterSpacing: "0.15px",
    },
    changePageBtn: {
      boxSize: "20px",
      color: "claydeck.white.primary",
      bgColor: "claydeck.brand.secondary",
      border: "0.5px solid #CDCCE2",
      borderRadius: "5px",
      boxShadow: "0px 14px 40px 0px rgba(0, 0, 0, 0.10)",
    },
    changePageBtnFaded: {
      boxSize: "20px",
      color: "claydeck.white.primary",
      bgColor: "claydeck.brand.secondary",
      border: "0.5px solid #CDCCE2",
      borderRadius: "5px",
      boxShadow: "0px 14px 40px 0px rgba(0, 0, 0, 0.10)",

      opacity: 0.21, // just toggle this and the other things in the script itself, this all is for demo only rn
    },
    pageNumberBox: {
      boxSize: "20px",
      border: "0.5px solid #CDCCE2",
      borderRadius: "5px",
      boxShadow: "0px 14px 40px 0px rgba(0, 0, 0, 0.10)",
    },
    selectedPageNumberBox: {
      boxSize: "20px",
      border: "1px solid #6750A4",
      borderRadius: "5px",
    },
    pageNumber: {
      color: "#9897A8",
      fontSize: "12px",
      fontWeight: 400,
    },
    selectedPageNumber: {
      color: "#6750A4",
      fontSize: "12px",
      fontWeight: 500,
    },
  };

  return (
    <Box paddingTop={"20px"} marginRight={"27px"} marginLeft={"53px"}>{console.log("tasks", todaysTasks)}
      <Flex width={"100%"} justify={"space-between"} marginBottom={"24px"}>
        <H1
          fontSize={"16px"}
          fontWeight={600}
          lineHeight={"120%"}
          letterSpacing={"0.2px"}
        >
          Today's Task
        </H1>
        <Box
          as="button"
          height={"24px"}
          width={"24px"}
          onClick={() => navigate("/tasks")}
        >
          <AddIcon
            boxSize={"24px"}
            color={"claydeck.white.primary"}
            bgColor={"claydeck.brand.secondary"}
            borderRadius={"15px"}
            padding={"4px"}
          />
        </Box>
      </Flex>
      <Box
        minHeight={"250px"}
        display={"flex"}
        flexDirection="column"
        justifyContent={"center"}
        alignItems={"center"}
      >
        {isLoading ? (
          <Spinner color="claydeck.brand.secondary" />
        ) : (
          <>
            <Flex
              height={"209.56"}
              direction={"column"}
              gap={"16px"}
              marginBottom={"24px"}
            >

              {todaysTasks.length > 0 && todaysTasks
                .slice(pageNumber * 4, (pageNumber + 1) * 4)

                .map((item, index) => {
                  return (
                    <Box
                      key={index}
                      minWidth={"320px"}
                      paddingX={"8px"}
                      paddingY={"12px"}
                      border={"0.75px solid #828282"}
                      borderLeft={`9px solid ${item.color}`}
                      borderRadius={"5px"}
                    >
                      <Pmd sx={styles.task}>{item.name}</Pmd>
                    </Box>
                  );
                })}
            </Flex>
            <Flex justify={"center"} align={"center"} gap={"28px"}>
              <Box
                as="button"
                disabled={todaysTasks?.length == 0}
                onClick={() => setPageNumber((prev) => prev - 1)}
              >
                <ChevronLeftIcon
                  sx={
                    pageNumber !== 0
                      ? styles.changePageBtn
                      : styles.changePageBtnFaded
                  }
                />
              </Box>
              <Flex gap={"6px"}>
                {Array?.from(
                  Array(Math.ceil(todaysTasks?.length / 4)),
                  (e, i) => {
                    return (
                      <Box
                        key={i}
                        as="button"
                        sx={
                          pageNumber == i
                            ? styles.selectedPageNumberBox
                            : styles.pageNumberBox
                        }
                        onClick={() => setPageNumber(i)}
                      >
                        <Psm
                          sx={
                            pageNumber == i
                              ? styles.selectedPageNumber
                              : styles.pageNumber
                          }
                        >
                          {i + 1}
                        </Psm>
                      </Box>
                    );
                  }
                )}
              </Flex>
              <Box
                as="button"
                disabled={todaysTasks?.length == 0}
                onClick={() => setPageNumber((prev) => prev + 1)}
              >
                <ChevronRightIcon
                  sx={
                    pageNumber !== Math.ceil(todaysTasks?.length / 4) - 1
                      ? styles.changePageBtn
                      : styles.changePageBtnFaded
                  }
                />
              </Box>
            </Flex>
          </>
        )}
      </Box>
    </Box>
  );
};

export default TaskList;
