import { useState } from "react";
import useRazorpay from "react-razorpay";
import { useLocation, useNavigate } from "react-router-dom";

import { Box, Flex, Spinner } from "@chakra-ui/react";
import H1 from "../../Components/Typography/Heading/H1";
import Pmd from "../../Components/Typography/Text/Pmd";
import ButtonSecondary from "../../Components/Button/ButtonSecondary";
import ButtonCheckboxPrimary from "../../Components/Button/ButtonCheckboxPrimary";
import PlanDetails from "./PlanDetails";

import { plans } from "../../constants/data";

import axios from "axios";
import { NEW_API_URL } from "../../redux/types";

const Plans = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [Razorpay] = useRazorpay();
    
    const [planPeriod, setPlanPeriod] = useState("monthly");
    const [selectedPlan, setSelectedPlan] = useState('');
    const [loading, setLoading] = useState(false);
    const[planSelectedError, setPlanSelectedError] = useState("");

    const handleSelectedPlan = (plan) => {
      setSelectedPlan(plan);
    }

    const validation = () => {
      if (!selectedPlan) {
        setPlanSelectedError("Please select a plan!"); // Set the error message
        return false;
      }
      setPlanSelectedError(""); // Clear the error message if a plan is selected
      return true;
    };

    const handleSubmit = () => {
      if (!validation()) return;

      setLoading(true);
      freeTrialSubscription();

      // selectedPlan === "Free Trial"
      //   ? freeTrialSubscription()
      //   : getSubscriptionId();
    };

    const handlePaymentResponse = (response) => {
      navigate("/join-workspace", {
        username: location.state.username,
        password: location.state.password,
      });
    };

    const getSubscriptionId = () => {
      axios
        .post(
          planPeriod === "monthly"
            ? `${NEW_API_URL}community/create_subscription/`
            : `${NEW_API_URL}community/create_one_time_order/`,
          {
            username: location.state.username,
            plan_type: selectedPlan,
          }
        )
        .then((res) => {
          console.log("generate order res", JSON.stringify(res.data));
          handlePlanSelect(res.data.id);
        })
        .catch((err) => {
          setLoading(false);
          console.log("generate order err", JSON.stringify(err.response.data));
        });
    };

    const handlePlanSelect = async (subscriptionId) => {
      var options = {
        key: "rzp_test_OCSY3TmxpYvn93",
        subscription_id: subscriptionId,
        name: "claydeck",
        description: "Monthly Subscription",
        image: "https://www.claydeck.com/logo.svg",
        handler: function (response) {
          handlePaymentResponse(response);
          alert(response.razorpay_payment_id);
          alert(response.razorpay_subscription_id);
          alert(response.razorpay_signature);
        },
        theme: {
          color: "#F37254",
        },
      };

      const rzp1 = new Razorpay(options);

      rzp1.on("payment.failed", function (response) {
        alert(response.error.reason);
      });

      rzp1.open();
    };

    const freeTrialSubscription = (route) => {
      axios
        .post(`${NEW_API_URL}community/create_free_subscription/`, {
          username: location.state.username,
          // plan_type: "free_trial"
          plan_type: planPeriod,
        })
        .then((res) => {
          setLoading(false);
          if (res.status === 200) {
            navigate("/join-workspace", {
              state: {
                username: location.state.username,
                password: location.state.password,
                first_name: location.state.first_name,
                last_name: location.state.last_name
              },
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log("\nfree trial err", JSON.stringify(err));
        });
    };

  return (
    <Box
      h={"100vh"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      gap={"56px"}
    >
      <H1 sx={{ fontSize: "42px" }} marginTop={"72px"}>
        Plans
      </H1>
      <Box marginX={"32px"}>
        <Flex justifyContent={"flex-end"} paddingY={"16px"}>
          <ButtonCheckboxPrimary
            onClick={() => {
              setPlanPeriod("monthly");
            }}
            checked={planPeriod === "monthly"}
          >
            Monthly
          </ButtonCheckboxPrimary>
          <ButtonCheckboxPrimary
            onClick={() => {
              setPlanPeriod("oneTime");
            }}
            checked={planPeriod === "oneTime"}
          >
            One Time Payment &#40;6 months&#41;
          </ButtonCheckboxPrimary>
        </Flex>
        <Flex justify={"center"} align={"center"} wrap={"wrap"} gap={"32px"}>
          {plans.map((plan, index) => (
            <PlanDetails
              key={index}
              data={plan}
              period={planPeriod}
              userDetails={location.state}
              selectedPlan={selectedPlan}
              choosePlan={handleSelectedPlan}
            />
          ))}
        </Flex>
      </Box>
        <Pmd color="red">{planSelectedError}</Pmd>
      <ButtonSecondary disabled={loading} width={"30%"} onClick={handleSubmit} color={"#323941"}>
        {loading ? <Spinner size={"sm"} /> : "Choose Plan"}
      </ButtonSecondary>
    </Box>
  );
};

export default Plans;