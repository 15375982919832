import React, { useEffect, useState } from "react";
import { Box, Container, Flex, Spinner } from "@chakra-ui/react";
import FeedItem from "./FeedItem";
import H1 from "../../Components/Typography/Heading/H1";
import H2 from "../../Components/Typography/Heading/H2";
import TrendingFeedItem from "./TrendingFeedItem";

import { getTodaysHeadline } from "../../utils/Request";

import "./FeedItem.css";
import COLORS from "../../Components/colors";

export default function Feed() {
  const [todaysHeadline, setTodaysHeadline] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
      let res = await getTodaysHeadline();
      setTodaysHeadline(res.data.items);
    } catch (error) {
      console.log("get feed error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex
      width={"100%"}
      backgroundColor={"claydeck.white.primary"}
      direction={{ base: "column", "2xl": "row" }}
      justify="space-between"
    >
      <Container>
        <H1 color={COLORS.black} fontSize={20} marginTop={10}>
          Today's Headlines
        </H1>
        {loading ? (
          <Spinner color="claydeck.brand.secondary" />
        ) : (
          todaysHeadline.map((item) => <FeedItem todaysHeadlineItem={item} />)
        )}
      </Container>
      <Box
        className="shadow-1"
        marginTop={50}
        borderTopRightRadius={10}
        borderTopLeftRadius={10}
        padding={5}
      >
        <Box
          backgroundColor={COLORS.darkPurple}
          height={20}
          marginTop={-5}
          marginLeft={-5}
          marginRight={-5}
          borderTopLeftRadius={10}
        />
        <H2
          fontSize={20}
          color={COLORS.purple}
          fontWeight="bold"
          margin="24px 0px"
        >
          Trending Feed
        </H2>
        {[1, 2, 3].map(() => (
          <TrendingFeedItem />
        ))}
      </Box>
    </Flex>
  );
}
