import axios from "axios"

export const getTodaysHeadline = async() => {
    let response = await axios.get('https://kk2z2518c6.execute-api.ap-south-1.amazonaws.com/staging/feed/get_all_news/1/rahul68@g.com/')
    return response
}

export const getFeedItem = async(item) => {
    let response = await axios.get(`https://kk2z2518c6.execute-api.ap-south-1.amazonaws.com/staging/feed/${item.news_fetched_date}/${item.news_id}`) 
    return response
}