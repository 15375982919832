import { useState, useEffect } from "react";

import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import Calendar from "react-calendar";
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import ButtonPrimary from "../../Components/Button/ButtonPrimary"

import {ArrowForwardIcon} from "@chakra-ui/icons";

import axios from "axios";
import moment from "moment/moment";
import { useSelector } from "react-redux";
import { NEW_API_URL } from "../../redux/types";
import { flatten } from "../../utils/array";

import "react-big-calendar/lib/css/react-big-calendar.css";
import "./index.css";

const localizer = momentLocalizer(moment);

const TaskCalendar = () => {
  const username = useSelector((state) => state.user.username);
  const selected_workspace = useSelector((state) => state.user);
  
  const currentWorkspaceId = selected_workspace.workspace_id;

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [events, setEvents] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  

  useEffect(() => {
    if(currentWorkspaceId !== undefined ){
      handleCalendar(new Date())
    }
  }, [currentWorkspaceId]);

  function getEvents(month, year) {

    const url = `${NEW_API_URL}gcalendar/get_month_events/${currentWorkspaceId}/${username}/${month}/${year}/`;
    axios
      .get(url)
      .then((r) => {
        let arr = [];
        for (const key in r.data) {
          arr.push(
            r.data[key].map((item) => {
              return {
                start: key,
                end: key,
                title: item.name,
              };
            })
          );
        }
        const data = flatten(arr);
        setEvents(data);
      })
      .catch((err) => {
        console.log("events error", err);
      });
  }

  function handleCalendar(date) {
      getEvents(moment(date).format("M"), moment(date).format("YYYY"));
  }

  return (
    <Box
      display={"flex"}
      flexDir={"column"}
      alignItems={"center"}
      padding={{ base: "0px 16px", md: "0px 32px", lg: "0px 46px" }}
    >
      <Flex direction={"column"}>
        <ButtonPrimary
          onClick={onOpen}
          width={"min-content"}
          color={"claydeck.text.secondary"}
          alignSelf={"flex-end"}
        >
          Detailed View
          <ArrowForwardIcon marginLeft={"6px"} />
        </ButtonPrimary>
        <Box width={"308px"}>
          <Calendar
            activeStartDate={selectedDate}
            onActiveStartDateChange={(p) => handleCalendar(p.activeStartDate)}
            tileClassName={({ date, view }) => {
              if (
                events.find(
                  (x) =>
                    moment(x.start).format("DD-MM-YYYY") ===
                    moment(date).format("DD-MM-YYYY")
                )
              ) {
                return "highlight";
              }
            }}
          />
        </Box>
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose} size={"6xl"} isCentered>
        <ModalOverlay />
        <ModalContent height={"750px"}>
          <ModalBody>
            <BigCalendar
              localizer={localizer}
              events={events}
              selectable={true}
              startAccessor="start"
              endAccessor="end"
              defaultDate={selectedDate}
              onNavigate={(date) => handleCalendar(date)}
              style={{ height: "700px" }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default TaskCalendar;
