import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Box, Container, Flex, Spinner } from "@chakra-ui/react";
import H1 from "../../Components/Typography/Heading/H1";
import H3 from "../../Components/Typography/Heading/H3";
import Pmd from "../../Components/Typography/Text/Pmd";
import ButtonSecondary from "../../Components/Button/ButtonSecondary";
import ButtonCheckboxPrimary from "../../Components/Button/ButtonCheckboxPrimary";

import axios from "axios";

const SurveryQuestions = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const [fetchLoading, setFetchLoading] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [userResponse, setUserResponse] = useState([]);

    useEffect(() => {
      fetchQuestions();
    }, [])

    const fetchQuestions = async () => {
      try {
        setFetchLoading(true);
        await axios
          .get("https://mocki.io/v1/0f7a4599-4148-4384-aa06-9008ce806bf0")
          .then((response) => setQuestions(response.data.questions))
          .catch((e) => console.error(e));
      } catch (e) {
        console.error(e);
      } finally {
        setFetchLoading(false);
      }
    };

    const handleOptionSelect = (questionId, optionId) => {
      // console.log(questionId, optionId);
      const newResponses = [...userResponse];
      const existingResponseIndex = newResponses.findIndex(
        (response) => response.id === questionId
      );

      if (existingResponseIndex !== -1) {
        newResponses[existingResponseIndex].answer = optionId;
      } else {
        newResponses.push({ id: questionId, answer: optionId });
      }
      // console.log(newResponses);
      setUserResponse(newResponses);
    };

    const handleSubmit = () => {
      const sortedResponses = userResponse.sort((a, b) => a.id - b.id);
      console.log(sortedResponses);
      navigate("/join-feed", {
        state: {
          username: location.state.username,
          password: location.state.password,
        },
      });
    };

    const styles = {
      heading: {
        color: "claydeck.brand.primary",
        textAlign: "center",
        fontSize: "32px",
        margin: "16px 0px 32px 0px",
      },
      question: {
        fontSize: "18px",
        letterSpacing: "0.2px",
        marginTop: "32px",
        marginBottom: "8px",
      },
      optionContainer: {
        alignItems: "center",
        paddingX: "12px",
        marginY: "3px",
      },
      optionText: {
        color: "claydeck.text.tertiary",
        fontSize: "16px",
        letterSpacing: "0.1px",
      },
      submitBtn: {
        h: "42px",
        w: "150px",
        color: "#fff",
        bgColor:
          userResponse.length === questions.length ? "#6750A4" : "#ebdef8",
      },
    };

    return (
      <Box bgColor={"claydeck.brand.secondary"} paddingY={"64px"}>
        <Container
          className="shadow-primary"
          maxW={"750px"}
          bgColor={"claydeck.white.primary"}
          borderRadius={"6px"}
          paddingX={"32px"}
          paddingY={"24px"}
        >
          <H1 sx={styles.heading}>Survery Questions</H1>
          {fetchLoading ? (
            <Flex justify={"center"}>
              <Spinner />
            </Flex>
          ) : (
            questions.map((question, index) => (
              <Box style={{ marginBottom: 10 }} key={index}>
                <H3 sx={styles.question}>{question.question}</H3>
                {question.options.map((option, index) => (
                  <Flex sx={styles.optionContainer} key={index}>
                    <ButtonCheckboxPrimary
                      bgColor={"rgba(0,0,0,0)"}
                      checked={userResponse.find(
                        (response) =>
                          response.id === question.id &&
                          response.answer === option.id
                      )}
                      onClick={() => handleOptionSelect(question.id, option.id)}
                    />
                    <Pmd sx={styles.optionText}>{option.option}</Pmd>
                  </Flex>
                ))}
              </Box>
            ))
          )}
          <Flex justify={"flex-end"} marginTop="32px">
            <ButtonSecondary
              sx={styles.submitBtn}
              // disabled={userResponse.length === questions.length}
              onClick={() => handleSubmit()}
            >
              Submit
            </ButtonSecondary>
          </Flex>
        </Container>
      </Box>
    );
}

export default SurveryQuestions;