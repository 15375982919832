import { useState } from "react";
import { useLocation, Link } from "react-router-dom";
// components
import { Box, Flex, Icon, IconButton, Image } from "@chakra-ui/react";
import Pmd from "../Components/Typography/Text/Pmd";
import SearchInput from "../Components/Input/SearchInput";
import ButtonPrimary from "../Components/Button/ButtonPrimary";
// icons
import {
  MdOutlineHome,
  MdOutlineDynamicFeed,
  MdOutlineForum,
} from "react-icons/md";
import {BiVideo} from "react-icons/bi";
import {BsCardChecklist} from "react-icons/bs";
import {FiMoreHorizontal} from "react-icons/fi";
import {LiaElementor} from "react-icons/lia";
import{PiBookOpenText} from "react-icons/pi";
import {TbLogout2} from "react-icons/tb";
import {HamburgerIcon, CloseIcon} from "@chakra-ui/icons";
// utils
import { useDispatch } from "react-redux";
import { logoutUser } from "../redux/actions/userActions";
import { datadogLogs } from '@datadog/browser-logs';

let t1 =  Math.floor(Date.now());

const Navbar = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const [display, setDisplay] = useState("none");
  const [moreOptions, setMoreOptions] = useState(false);

  const paths = [
    {
      name: "Home",
      path: "/home",
      icon: MdOutlineHome,
    },
    {
      name: "Room",
      path: "/room",
      icon: BiVideo,
    },
    {
      name: "Library",
      path: "/library",
      icon: PiBookOpenText,
    },
    {
      name: "Feeds",
      path: "/feeds",
      icon: MdOutlineDynamicFeed,
    },
    {
      name: "Forums",
      path: "/forums",
      icon: MdOutlineForum,
    },
    {
      name: "Mentor",
      path: "/mentor-workspace",
      icon: LiaElementor,
    },
  ];

  const logPath = (item) => {
    datadogLogs.logger.info(item.name, {name: item.path, id: 123, responseTime: t1});
  }

  const styles = {
    menuContainer: {
      bgColor: "#fff",
      display: { base: display, xl: "flex" },
      flexDirection: { base: "column-reverse", xl: "row" },
      gap: "38px",
      borderRadius: "6px",
      boxShadow: { base: "lg", xl: "none" },
      padding: { base: "28px", xl: "0px" },
      paddingRight: { xl: "16px" },
      position: { base: "absolute", xl: "relative" },
      right: "10px",
      zIndex: 2,
    },
    navBtn: {
      height: { base: "50px", xl: "70px" },
      width: { base: "50px", xl: "70px" },
      flexDirection: { xl: "column" },
      justifyContent: "center",
      alignItems: "center",
      gap: { base: "8px", xl: "4px" },
      paddingTop: "12px",
      paddingBottom: "16px",
    },
    link: {
      fontSize: "14px",
      lineHeight: "16px",
      letterSpacing: "0.5px",
    },
  };

  return (
    <Flex
      height={"116px"}
      bgColor={"claydeck.white.primary"}
      justify={"space-between"}
      align={"center"}
      paddingX={{ base: "32px", xl: "58px" }}
    >
      <Image
        src={"./assets/images/claydeck-logo.png"}
        height={{ base: "45px", xl: "68px" }}
      />
      <SearchInput />
      <Box position={"relative"}>
        <ButtonPrimary
          display={{ base: "inline-block", xl: "none" }}
          onClick={() =>
            setDisplay((prev) => (prev === "flex" ? "none" : "flex"))
          }
        >
          {display === "flex" ? <CloseIcon /> : <HamburgerIcon />}
        </ButtonPrimary>
        <Flex sx={styles.menuContainer}>
          <Flex
            direction={{ base: "column", xl: "row" }}
            gap={{ lg: "16px", xl: "24px" }}
            align={"center"}
          >
            {paths.map((item, index) => {
              const selected = item.path === location.pathname;
              const selected_item = item
              return (
                <Link key={index} to={item.path} onClick= {() => {logPath(selected_item)}}>
                  <Flex sx={styles.navBtn}>
                    <Icon
                      as={item.icon}
                      boxSize={"24px"}
                      color={selected ? "#6750A4" : "#828282"}
                    />
                    <Pmd
                      sx={styles.link}
                      color={selected ? "#6750A4" : "#828282"}
                      fontWeight={selected ? 500 : 400}
                    >
                      {item.name}
                    </Pmd>
                  </Flex>
                </Link>
              );
            })}
            <Box position={"relative"}>
              <Flex
                sx={styles.navBtn}
                cursor={"pointer"}
                onClick={() => setMoreOptions((prev) => !prev)}
              >
                <Icon
                  as={FiMoreHorizontal}
                  boxSize={"24px"}
                  color={"#828282"}
                />
                <Pmd sx={styles.link} color={"#828282"} fontWeight={400}>
                  More
                </Pmd>
              </Flex>
              <Flex
                className={"shadow-primary"}
                bgColor={"claydeck.white.primary"}
                width={"max-content"}
                direction={"column"}
                padding={"4px 8px"}
                position={"absolute"}
                left={"-18px"}
                top={"32px"}
                display={moreOptions ? "flex" : "none"}
              >
                <Box
                  as="button"
                  onClick={() => props.setSidebarVisible()}
                  display={"flex"}
                  alignItems={"center"}
                  gap={"8px"}
                  padding={"4px 8px"}
                >
                  <Icon
                    as={BsCardChecklist}
                    boxSize={"20px"}
                    color={"#828282"}
                  />
                  <Pmd
                    sx={styles.link}
                    color={"#828282"}
                    fontWeight={400}
                    marginBottom={0}
                  >
                    Task List
                  </Pmd>
                </Box>
                <Box
                  as="button"
                  onClick={() => dispatch(logoutUser())}
                  display={"flex"}
                  alignItems={"center"}
                  gap={"8px"}
                  padding={"4px 8px"}
                >
                  <Icon as={TbLogout2} boxSize={"20px"} color={"#828282"} />
                  <Pmd
                    sx={styles.link}
                    color={"#828282"}
                    fontWeight={400}
                    marginBottom={0}
                  >
                    Logout
                  </Pmd>
                </Box>
              </Flex>
            </Box>
          </Flex>
          <Image
            src="./assets/images/profile-img.png"
            height={{ base: "42px", xl: "60px" }}
            width={{ base: "42px", xl: "60px" }}
          />
        </Flex>
      </Box>
    </Flex>
  );
};

export default Navbar;
