import React from "react";
import Nav from "./Nav";
import { GridItem, SimpleGrid, Grid } from "@chakra-ui/react";
import Pmd from "../../Components/Typography/Text/Pmd";
import Psm from "../../Components/Typography/Text/Psm";
import H1 from "../../Components/Typography/Heading/H1";
import H3 from "../../Components/Typography/Heading/H3";
import MainHeading from "../../Components/Typography/Heading/MainHeading";
import COLORS from "../../Components/colors";
import "./LandingPage.css";
import section3Icon1 from "../../assets/landing/section3Icon1.png";
import section3Icon2 from "../../assets/landing/section3Icon2.png";
import section3Icon3 from "../../assets/landing/section3Icon3.png";
import section5Img1 from "../../assets/landing/section5Img1.png";
import section5Img2 from "../../assets/landing/section5Img2.png";
import section5Img3 from "../../assets/landing/section5Img3.png";
import section6Img1 from "../../assets/landing/section6Img1.png";
import section6Img2 from "../../assets/landing/section6Img2.png";
import section6Img3 from "../../assets/landing/section6Img3.png";
import section6Img4 from "../../assets/landing/section6Img4.png";
import section7Avatar from "../../assets/landing/section7Avatar.png";
import section7Logo from "../../assets/landing/section7Logo.png";
import section1Asset from "../../assets/landing/section1Asset.svg";

export default function LandingPage() {
  return (
    <div>
      {/* NavBar */}
      <Nav />

      {/* Section 1 */}
      <div>
        <div className="d-flex justify-content-center align-items-center">
          <img src={section1Asset} style={{ width: "100vw" }} />
        </div>
        <div className="section-1-info">
          <MainHeading fontWeight={800} className="section-1-main-heading mb-4">
            The AI-Based Community UPSC Preparation Platform
          </MainHeading>
          <Pmd>
            We provide you with the latest UPSC materials and community learning
            environment to help you growing. We’ll bring you together. With
            around 50+ study topic rooms, 24+ professional IAS study classrooms
            and a separate course learning platform, where you can learn by
            yourself, apart from group study sessions.
          </Pmd>
          <button className="section-1-btn rounded px-4 py-2 mt-5">
            Get Started
          </button>
        </div>
      </div>

      {/* Section 2 */}
      <SimpleGrid columns={1} spacing="40px" className="mb-5">
        <GridItem
          w="100%"
          h={{ lg: "20vh", md: "40vh" }}
          style={{ padding: "10%", paddingLeft: "20%", paddingRight: "20%" }}
          className="d-flex justify-content-center flex-column text-center"
        >
          <H3 className="mt-5" color={COLORS.darkPurple}>
            Preparation Strategy
          </H3>
          <MainHeading>
            Here are the three main questions on of students while preparing
          </MainHeading>
        </GridItem>
      </SimpleGrid>

      {/* Section 3 */}
      <SimpleGrid
        columns={{ sm: 1, md: 3, lg: 3 }}
        spacing="40px"
        className="my-5"
        style={{ paddingLeft: "10vw", paddingRight: "10vw" }}
      >
        <GridItem
          w="100%"
          style={{ backgroundColor: COLORS.purpleTertiary }}
          h={{ lg: "40vh", md: "50vh" }}
          className="d-flex justify-content-center rounded flex-column p-5 shadow"
        >
          <div className="d-flex flex-row align-items-center my-4">
            <div
              className="p-2 mx-2 rounded"
              style={{ backgroundColor: COLORS.background }}
            >
              <img src={section3Icon1} alt="section3Icon1" />
            </div>
            <H1 style={{ color: COLORS.white }}>Syllabus</H1>
          </div>
          <Pmd style={{ color: COLORS.white }}>
            What all the content I need to cover for best practice? But, we
            offer lesson plan strategy. So, no need to worry!
          </Pmd>
        </GridItem>
        <GridItem
          w="100%"
          style={{ backgroundColor: COLORS.white }}
          h={{ lg: "40vh", md: "50vh" }}
          className="d-flex justify-content-center rounded flex-column p-5 shadow"
        >
          <div className="d-flex flex-row align-items-center my-4">
            <div
              className="p-2 mx-2 rounded"
              style={{ backgroundColor: COLORS.lightBlue }}
            >
              <img src={section3Icon2} alt="section3Icon2" />
            </div>
            <H1 style={{ color: COLORS.black }}>Time Management</H1>
          </div>
          <Pmd style={{ color: COLORS.black }}>
            How do I allocate my time so effectively to make its best use? Here,
            we give you specified to-do calculator.
          </Pmd>
        </GridItem>
        <GridItem
          w="100%"
          style={{ backgroundColor: COLORS.white }}
          h={{ lg: "40vh", md: "50vh" }}
          className="d-flex justify-content-center rounded flex-column p-5 shadow"
        >
          <div className="d-flex flex-row align-items-center my-4">
            <div
              className="p-2 mx-2 rounded"
              style={{ backgroundColor: COLORS.lightPink }}
            >
              <img src={section3Icon3} alt="section3Icon3" />
            </div>
            <H1 style={{ color: COLORS.black }}>Organizing Skill</H1>
          </div>
          <Pmd style={{ color: COLORS.black }}>
            How to spend time on the right topics to have a good preparation
            schedule? We’ll give reminders to stay alert!
          </Pmd>
        </GridItem>
      </SimpleGrid>

      {/* Section 4 */}
      <SimpleGrid columns={1} spacing="40px">
        <GridItem
          w="100%"
          h={{ lg: "20vh", md: "40vh" }}
          style={{ padding: "10%", paddingLeft: "10%", paddingRight: "10%" }}
          className="d-flex justify-content-center flex-column mt-5"
        >
          <H3 className="mt-5" color={COLORS.darkPurple}>
            Study Groups
          </H3>
          <MainHeading>Explore Trending Groups of Community</MainHeading>
          <Pmd color={COLORS.grey}>
            Let's join our famous class, the knowledge provided will definitely
            be useful for you.
          </Pmd>
        </GridItem>
      </SimpleGrid>

      {/* Section 5 */}
      <SimpleGrid
        columns={{ sm: 1, md: 3, lg: 3 }}
        spacing="40px"
        style={{ paddingLeft: "10vw", paddingRight: "10vw" }}
      >
        <GridItem
          w="100%"
          style={{ backgroundColor: COLORS.white }}
          h={{ lg: "70vh", md: "80vh" }}
          className="d-flex justify-content-center rounded flex-column p-5 shadow"
        >
          <img src={section5Img1} alt="section5Img1" />
          <H3 className="mt-3" color={COLORS.purple4}>
            General
          </H3>
          <H1>Science Group</H1>
          <Pmd color={COLORS.grey}>
            We’ll discuss chemistry, physics, biology and botany topics in
            detail. Let’s connect!
          </Pmd>
          <div className="d-flex flex-row align-items-center">
            <div
              className="circle-small"
              style={{ backgroundColor: COLORS.green }}
            />
            <Psm
              style={{ marginBottom: 0 }}
              color={COLORS.purple4}
              className="mx-2"
            >
              250 Active Students
            </Psm>
            <Psm style={{ marginBottom: 0 }} color={COLORS.grey}>
              (325)
            </Psm>
          </div>
          <button className="p-2 rounded mt-3 section-5-btn">
            Science Group
          </button>
        </GridItem>
        <GridItem
          w="100%"
          style={{ backgroundColor: COLORS.white }}
          h={{ lg: "70vh", md: "80vh" }}
          className="d-flex justify-content-center rounded flex-column p-5 shadow"
        >
          <img src={section5Img2} alt="section5Img2" />
          <H3 className="mt-3" color={COLORS.purple4}>
            General
          </H3>
          <H1>Science Group</H1>
          <Pmd color={COLORS.grey}>
            We’ll discuss chemistry, physics, biology and botany topics in
            detail. Let’s connect!
          </Pmd>
          <div className="d-flex flex-row align-items-center">
            <div
              className="circle-small"
              style={{ backgroundColor: COLORS.green }}
            />
            <Psm
              style={{ marginBottom: 0 }}
              color={COLORS.purple4}
              className="mx-2"
            >
              250 Active Students
            </Psm>
            <Psm style={{ marginBottom: 0 }} color={COLORS.grey}>
              (325)
            </Psm>
          </div>
          <button className="p-2 rounded mt-3 section-5-btn">
            Science Group
          </button>
        </GridItem>
        <GridItem
          w="100%"
          style={{ backgroundColor: COLORS.white }}
          h={{ lg: "70vh", md: "80vh" }}
          className="d-flex justify-content-center rounded flex-column p-5 shadow"
        >
          <img src={section5Img3} alt="section5Img3" />
          <H3 className="mt-3" color={COLORS.purple4}>
            General
          </H3>
          <H1>Chess Learning Group</H1>
          <Pmd color={COLORS.grey}>
            Apart from learning, let’s have a chess play. It will help you grow
            intelligent. C’mon join!
          </Pmd>
          <div className="d-flex flex-row align-items-center">
            <div
              className="circle-small"
              style={{ backgroundColor: COLORS.green }}
            />
            <Psm
              style={{ marginBottom: 0 }}
              color={COLORS.purple4}
              className="mx-2"
            >
              250 Active Students
            </Psm>
            <Psm style={{ marginBottom: 0 }} color={COLORS.grey}>
              (325)
            </Psm>
          </div>
          <button className="p-2 rounded mt-3 section-5-btn">
            Science Group
          </button>
        </GridItem>
      </SimpleGrid>

      {/* Section 6 */}
      <SimpleGrid
        columns={{ sm: 1, md: 2, lg: 2 }}
        style={{ paddingLeft: "10vw", paddingRight: "10vw", marginTop: 150 }}
      >
        <GridItem
          w="100%"
          style={{ backgroundColor: COLORS.white }}
          className="d-flex justify-content-center rounded flex-row p-5"
        >
          <img
            src={section6Img1}
            alt="section6Img1"
            className="me-3"
            style={{ width: 100, height: 100 }}
          />
          <div className="d-flex flex-column">
            <H1>Regular discussion rooms to develop topic understanding</H1>
            <Pmd color={COLORS.grey}>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s
            </Pmd>
          </div>
        </GridItem>
        <GridItem
          w="100%"
          style={{ backgroundColor: COLORS.white }}
          className="d-flex justify-content-center rounded flex-row p-5"
        >
          <img
            src={section6Img2}
            alt="section6Img2"
            className="me-3"
            style={{ width: 100, height: 100 }}
          />
          <div className="d-flex flex-column">
            <H1>Mentorship sessions from experienced instructors</H1>
            <Pmd color={COLORS.grey}>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s
            </Pmd>
          </div>
        </GridItem>
        <GridItem
          w="100%"
          style={{ backgroundColor: COLORS.white }}
          className="d-flex justify-content-center rounded flex-row p-5"
        >
          <img
            src={section6Img3}
            alt="section6Img3"
            className="me-3"
            style={{ width: 100, height: 100 }}
          />
          <div className="d-flex flex-column">
            <H1>AL powered individual answer writing feedback</H1>
            <Pmd color={COLORS.grey}>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s
            </Pmd>
          </div>
        </GridItem>
        <GridItem
          w="100%"
          style={{ backgroundColor: COLORS.white }}
          className="d-flex justify-content-center rounded flex-row p-5"
        >
          <img
            src={section6Img4}
            alt="section6Img4"
            className="me-3"
            style={{ width: 100, height: 100 }}
          />
          <div className="d-flex flex-column">
            <H1>
              Arranged library resources, practice tests, daily curated news and
              more
            </H1>
            <Pmd color={COLORS.grey}>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s
            </Pmd>
          </div>
        </GridItem>
      </SimpleGrid>

      {/* Section 7 */}
      <SimpleGrid columns={1} spacing="40px" className="my-5">
        <GridItem
          w="100%"
          h={{ lg: "20vh", md: "40vh" }}
          style={{ padding: "10%", paddingLeft: "20%", paddingRight: "20%" }}
          className="d-flex justify-content-center flex-column text-center align-items-center my-5"
        >
          <img
            src={section7Logo}
            alt="section7Logo"
            width={"15%"}
            className="mb-3"
          />
          <MainHeading>
            Polity group is fantastic! It is Master platform for those looking
            to prepare for UPSC, or other examinations.
          </MainHeading>
          <img
            src={section7Avatar}
            alt="section7Avatar"
            className="mt-3 mb-2"
          />
          <Psm style={{ marginBottom: 0 }}>Jacob Jones</Psm>
          <Psm color={COLORS.grey}>Civil Service Student, Jaipur</Psm>
        </GridItem>
      </SimpleGrid>

      {/* Footer  */}
      <Grid
        className="mt-5"
        templateAreas={{
          lg: `"logo logo product company social legal"`,
          md: `"logo logo" 
      "product company"
      "social legal"`,
          sm: `"logo" 
      "logo" 
      "product" 
      "company" 
      "social" 
      "legal"`,
        }}
        gap="1"
        style={{
          padding: 100,
          marginTop: 100,
          backgroundColor: COLORS.darkBlue,
        }}
      >
        <GridItem
          area={"logo"}
          style={{ padding: "10%", paddingLeft: "20%", paddingRight: "20%" }}
          className="d-flex justify-content-center flex-column"
        >
          <H1 color={COLORS.white}>Claydeck</H1>
          <Pmd color={COLORS.white} className="mt-4">
            Top learning experiences that create more talent in the world.
          </Pmd>
        </GridItem>
        <GridItem
          className="d-flex justify-content-center flex-column"
          area={"product"}
        >
          <Psm color={COLORS.grey} className="mb-1">
            Product
          </Psm>
          <Pmd color={COLORS.white} className="my-1">
            Overview
          </Pmd>
          <Pmd color={COLORS.white} className="my-1">
            Features
          </Pmd>
          <Pmd color={COLORS.white} className="my-1">
            Solutions
          </Pmd>
          <Pmd color={COLORS.white} className="my-1">
            Tutorial
          </Pmd>
          <Pmd color={COLORS.white} className="my-1">
            Pricing
          </Pmd>
        </GridItem>
        <GridItem
          className="d-flex justify-content-center flex-column"
          area={"company"}
        >
          <Psm color={COLORS.grey} className="mb-1">
            Company
          </Psm>
          <Pmd color={COLORS.white} className="my-1">
            About Us
          </Pmd>
          <Pmd color={COLORS.white} className="my-1">
            Careers
          </Pmd>
          <Pmd color={COLORS.white} className="my-1">
            Press
          </Pmd>
          <Pmd color={COLORS.white} className="my-1">
            News
          </Pmd>
        </GridItem>
        <GridItem
          className="d-flex justify-content-center flex-column"
          area={"social"}
        >
          <Psm color={COLORS.grey} className="mb-1">
            Social
          </Psm>
          <Pmd color={COLORS.white} className="my-1">
            Twitter
          </Pmd>
          <Pmd color={COLORS.white} className="my-1">
            LinkedIn
          </Pmd>
          <Pmd color={COLORS.white} className="my-1">
            Github
          </Pmd>
          <Pmd color={COLORS.white} className="my-1">
            Dribble
          </Pmd>
        </GridItem>
        <GridItem
          className="d-flex justify-content-center flex-column"
          area={"legal"}
        >
          <Psm color={COLORS.grey} className="mb-1">
            Legal
          </Psm>
          <Pmd color={COLORS.white} className="my-1">
            Terms
          </Pmd>
          <Pmd color={COLORS.white} className="my-1">
            Privacy
          </Pmd>
          <Pmd color={COLORS.white} className="my-1">
            Cookies
          </Pmd>
          <Pmd color={COLORS.white} className="my-1">
            Contact
          </Pmd>
        </GridItem>
      </Grid>
    </div>
  );
}
