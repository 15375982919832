import React from "react";
import { Box, Flex, Image, Text, Grid } from "@chakra-ui/react";
import COLORS from "../../Components/colors";
import book_logo from "../../assets/book_logo.png";
import notes2_blurred from "../../assets/notes2_blurred.png";
import recommended from "../../assets/recommended.png";

import Psm from "../../Components/Typography/Text/Psm";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ExamPracticeMAterial = () => {
  const navigate = useNavigate();

  let data = [
    {
      id: 1,
      image: book_logo,
      nav_path: "./mock-papers",
      heading: "Mock Papers",
    },
    {
      id: 2,
      image: notes2_blurred,
      nav_path: "./subjective-test",
      heading: "Subjective Test",
    },
    {
      id: 3,
      image: recommended,
      nav_path: "./practice-quize",
      heading: "Practice Quize",
    },
  ];

  return (
    <Flex gap={"4px"} flexWrap={"wrap"}>
      {data.map((item, index) => {
        return (
          <Flex
            key={index}
            onClick={() => {
              navigate(item.nav_path, { state: { item: item } });
            }}
            cursor="pointer"
            height={"210px"}
            maxW="180px"
            backgroundColor={COLORS.white}
            justifyContent={"center"}
            direction={"column"}
            alignItems={"center"}
            padding={2}
            margin={6}
          >
            <Image
              src={item.image}
              alt={item.heading}
              height="150px"
              objectFit="contain"
            />
            <Psm
              className="my-2 text-align-center"
              color={COLORS.darkPurple}
              textAlign="center"
            >
              {item.heading}
            </Psm>
          </Flex>
        );
      })}
    </Flex>
  );
};

export default ExamPracticeMAterial;
