import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useGoogleLogin } from "react-google-login";

import {
  Box,
  Grid,
  GridItem,
  Checkbox,
  Image,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import Pmd from "../../Components/Typography/Text/Pmd";
import H2 from "../../Components/Typography/Heading/H2";
import InputOutline from "../../Components/Input/InputOutline";
import InputPassword from "../../Components/Input/InputPassword";
import ButtonPrimary from "../../Components/Button/ButtonPrimary";
import ButtonSecondary from "../../Components/Button/ButtonSecondary";

import axios from "axios";
import { useDispatch } from "react-redux";
import {API_URL_STAGING} from "../../redux/types";
import { loginUser } from "../../redux/actions/userActions";
import { datadogLogs } from '@datadog/browser-logs';

let t1 =  Math.floor(Date.now());

const clientId =
  "105604948407-qgjbl3k27icekteikgjq56tehc4tlnuc.apps.googleusercontent.com";

const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [resetPasswordEmail, setResetPasswordEmail] = useState("");
  const [resetPasswordLoading, setResetpasswordLoading] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const onSuccess = (res) => {
    console.log("Login Success: currentUser:", res.profileObj);
  };

  const onFailure = (res) => {
    console.log("Login failed res:", res);
  };

  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId,
    isSignedIn: true,
    accessType: "offline",
  });

  const handleSubmit = () => {
    setIsSigningIn(true);
    const userData = {
      username: email,
      password: password,
    };

    datadogLogs.logger.info('Signin', {name: 'Signin button clicked', id: 123, responseTime: t1});

    dispatch(loginUser(userData, rememberMe, navigate));
    setIsSigningIn(false);
  };

  // reset password functions
  const closeModal = () => {
    onClose();
    setResetPasswordEmail("");
  };

  const submitResetPasswordEmail = () => {
    setResetpasswordLoading(true);

    // email validation
    const regexEmail =
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/;
    const testEmail = regexEmail.test(resetPasswordEmail);
    if (!testEmail) {
      alert("Please enter a valid email address.");
      return;
    }

    const url = `${API_URL_STAGING}user/send-reset-password-email/`;
    const body = {
      email: email,
    };
    axios
      .post(url, body)
      .then((res) => {
        console.log("email reset res", res.data);
        alert("Email sent successfully! Please check your mail.");
        setResetpasswordLoading(false);
        closeModal();
      })
      .catch((error) => {
        console.log("email reset error", error);
        alert("An error occured, please try again.");
        setResetpasswordLoading(false);
      });
  };

  return (
    <Grid
      columns={{ base: 1, lg: 2 }}
      templateColumns={{ base: "1fr", lg: "1.5fr 2fr" }}
    >
      <GridItem
        w="100%"
        h="100vh"
        bgColor={"claydeck.brand.secondary"}
        display={{ base: "none", lg: "flex" }}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Image
          src={"./assets/images/mockup-with-bg.png"}
          objectFit={"contain"}
          alt={"mobile-app-mockup"}
          width={"100%"}
          maxHeight={"770px"}
        />
      </GridItem>
      <GridItem
        w="100%"
        h="100vh"
        display="flex"
        flexDirection={"column"}
        alignItems={"center"}
      >
        <Box
          height={"100%"}
          width={{ base: "100%", lg: "80%" }}
          maxWidth={"620px"}
          display={"flex"}
          gap={{ base: "32px", md: "0px" }}
          flexDirection={"column"}
          justifyContent={"space-evenly"}
          padding={{ base: "16px", md: "32px", lg: "0px" }}
        >
          <Image
            height={"80px"}
            objectFit={"contain"}
            src={"./assets/images/claydeck-logo.png"}
            alt={"site-logo"}
            alignSelf={"flex-start"}
          />
          <Box>
            <Box marginBottom={"52px"}>
              <Pmd color={"claydeck.text.tertiary"} marginBottom={"16px"}>
                Sign in with?
              </Pmd>
              <ButtonSecondary
                onClick={signIn}
                width={"150px"}
                marginRight={"24px"}
              >
                Google
              </ButtonSecondary>
            </Box>
            {/* <ButtonSecondary width={"150px"}>Facebook</ButtonSecondary> */}
            <Grid gap={"32px"}>
              <GridItem>
                <Pmd
                  htmlFor="email"
                  color={"claydeck.text.tertiary"}
                  mb={"16px"}
                >
                  Email
                </Pmd>
                <InputOutline
                  type="email"
                  name="email"
                  placeholder={"example@email.com"}
                  value={email}
                  onChange={(event) => setEmail(event.currentTarget.value)}
                />
              </GridItem>
              <GridItem>
                <Pmd
                  htmlFor="password"
                  color={"claydeck.text.tertiary"}
                  mb={"16px"}
                >
                  Password
                </Pmd>
                <InputPassword
                  value={password}
                  onChange={(event) => setPassword(event.currentTarget.value)}
                />
              </GridItem>
            </Grid>
            <Flex
              direction={{ base: "column", md: "row" }}
              gap={{ base: "12px", md: "0px" }}
              justify={"space-between"}
              alignItems={{ base: "flex-start", md: "center" }}
              marginTop={"18px"}
            >
              <Checkbox
                isChecked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
              >
                <H2>Remember Me</H2>
              </Checkbox>
              <ButtonPrimary onClick={onOpen}>Forgot Password?</ButtonPrimary>
            </Flex>
          </Box>
          <Box
            width={"100%"}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
          >
            <ButtonSecondary
              width={"60%"}
              minWidth={"250px"}
              maxWidth={"400px"}
              height={"56px"}
              color={"white"}
              bg={"claydeck.brand.primary"}
              borderRadius={"10px"}
              marginBottom={0}
              isSigningIn={isSigningIn}
              loadingText={"Signing In"}
              onClick={() => handleSubmit()}
            >
              Sign In
            </ButtonSecondary>
            <Box display={"flex"} marginTop={"12px"}>
              <Pmd color={"claydeck.text.tertiary"}>Don't have an account?</Pmd>
              <Link to={"/signup"} style={{ margin: "0px 12px" }}>
                <H2 color={"claydeck.text.primary"}>Sign Up</H2>
              </Link>
            </Box>
          </Box>
        </Box>
      </GridItem>
      <Modal isCentered isOpen={isOpen} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Reset Password</ModalHeader>
          <ModalBody>
            <Pmd
              color={"claydeck.text.primary"}
              letterSpacing={"0.2px"}
              mb={"16px"}
            >
              You will recieve a password reset link on the email you enter
              below
            </Pmd>
            <InputOutline
              type="email"
              name="reset-password-email"
              placeholder={"example@email.com"}
              value={resetPasswordEmail}
              onChange={(event) =>
                setResetPasswordEmail(event.currentTarget.value)
              }
            />
          </ModalBody>
          <ModalFooter>
            <ButtonPrimary colorScheme="blue" mr={3} onClick={closeModal}>
              Close
            </ButtonPrimary>
            <ButtonSecondary variant="ghost" onClick={submitResetPasswordEmail}>
              Send Email
            </ButtonSecondary>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Grid>
  );
};

export default SignIn;
