import { InputGroup, Input, InputLeftElement, Icon } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { NEW_SEARCHQUERY } from "../../redux/types";

const SearchInput = (props) => {
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    // setSearch("");
  }, [location.pathname]);

  const handleSearch = () => {
    if (search) {
      console.log("search", search);
      dispatch({ type: NEW_SEARCHQUERY, payload: search });
      setSearch("");
      navigate(`/search`);
    }
  };

  return (
    <InputGroup
      height={{ base: "40px", lg: "48px" }}
      minW={"250px"}
      maxW={{ base: "300px", lg: "427px" }}
      bgColor={"claydeck.brand.hover"}
      borderRadius={"5px"}
      marginX={{ base: "24px", lg: "48px" }}
    >
      <InputLeftElement height={{ base: "40px", lg: "48px" }}>
        <Icon
          as={AiOutlineSearch}
          boxSize={{ base: "16px", lg: "20px" }}
          color={"#AEAAAE"}
        />
      </InputLeftElement>
      <Input
        placeholder="Search here"
        _placeholder={{
          color: "#AEAAAE",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: "100%",
          letterSpacing: "0.25px",
        }}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            handleSearch();
          }
        }}
        height={{ base: "40px", lg: "48px" }}
        border={"0px"}
        borderRadius={"5px"}
        {...props}
      />
    </InputGroup>
  );
};

export default SearchInput;
