import React from "react";
import COLORS from "../../../../Components/colors";
import H1 from "../../../../Components/Typography/Heading/H1";

export default function PlanBanner() {
  return (
    <div
      className="d-flex p-4 rounded m-4 pb-5"
      style={{ backgroundColor: COLORS.darkPurple }}
    >
      <H1 style={{ color: COLORS.offWhite }}>PREMIUM PLAN</H1>
    </div>
  );
}
