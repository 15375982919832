import React from "react";
import { Box, Heading } from "@chakra-ui/react";
import H1 from "../../../../Components/Typography/Heading/H1";
import Psm from "../../../../Components/Typography/Text/Psm";

export default function Notes() {
  return (
    <Box w={"100%"} background={"claydeck.white.primary"}>
      <H1 margin={4} marginTop={8} marginBottom={3}>
        Notes
      </H1>
      <Psm className="mx-4">Coming Soon</Psm>
    </Box>
  );
}
