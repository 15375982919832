import React, { useState } from "react";
import PlanBanner from "./StudyResourceComps/components/PlanBanner";
import COLORS from "../../Components/colors";
import { Box, Flex, Text } from "@chakra-ui/react";
import H2 from "../../Components/Typography/Heading/H2";
import StudyResources from "./StudyResource";

import ExamPracticeMAterial from "./ExamPracticeMaterial";

export default function Library() {
  const [typeOfLibraryContentToBeShown, setTypeOfLibraryContentToBeShown] =
    useState("Study Resources");

  return (
    <Box width="100%">
      <PlanBanner />
      <Flex direction="row">
        {["Study Resources", "Exam practice material"].map((item) => (
          <Box
            key={item}
            onClick={() => setTypeOfLibraryContentToBeShown(item)}
            className="mx-4 cursor"
            borderBottomWidth={3}
            borderBottomColor={
              item === typeOfLibraryContentToBeShown
                ? COLORS.purple
                : COLORS.white
            }
          >
            <H2 color={COLORS.black}>{item}</H2>
          </Box>
        ))}
      </Flex>
      {typeOfLibraryContentToBeShown === "Study Resources" ? (
        <StudyResources />
      ) : (
        <ExamPracticeMAterial />
      )}
    </Box>
  );
}
